import React, { useState } from 'react'
import { ComponentInput, ContentHeader, handleSubmitForStore, onChange } from '../../Components/Components'

const AddTeam = () => {

    const [error, setError] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [values, setValues] = useState({
        name: "",
    });

    return (
        <>
            <ContentHeader
                h1='Add Team'
                buttons={
                    [
                        { url: 'teams', label: 'All Teams' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'teams', label: 'Teams', active: false },
                        { url: 'team/add', label: 'Add', active: true },
                    ]
                }
            />
            {/* Main content */}
            <div className='border rounded-2 shadow-sm'>
                <div className="row p-3">
                    <form>
                        <div className="col-12">
                            <div className="mb-3">
                                <ComponentInput
                                    name='name'
                                    type='text'
                                    displayName='Category Name'
                                    className='form-control'
                                    value={values['name']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                        </div>
                        <div className='col-12 pt-3'>
                            <button onClick={
                                (e) => {
                                    handleSubmitForStore(
                                        e,
                                        'team/add',
                                        {
                                            name: values.name,
                                        },
                                        setError,
                                        setNotificationMessage
                                    )
                                }
                            } className="btn btn-primary">Submit</button>
                            {notificationMessage}
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddTeam