import React, { useEffect, useState } from 'react';
import { AxiosGet, ContentHeader } from '../../Components/Components';
import { CustomTable, SelectColumnFilter } from '../../Components/CustomTable/CustomReactTable';
import { Link } from 'react-router-dom';


const Customers = () => {

    // React-table start
    const [data, setData] = useState([]);

    const columns = React.useMemo(
        () => [
            {
                accessor: 'company_name',
                Header: 'Company Name',
                Cell: ({ cell }) => (
                    <Link className='mx-2' to={`/contact/view/${cell.row.values.id}`}>
                        {cell.value}
                    </Link>
                )
            },
            {
                accessor: 'primary_contact_name',
                Header: 'Contact Name',
            },
            {
                accessor: 'address',
                Header: 'Address'
            },
            {
                accessor: 'phone',
                Header: 'Phone'
            },
            {
                accessor: 'website',
                Header: 'Website'
            },
            {
                accessor: 'category.name',
                Header: 'Category',
                Filter: SelectColumnFilter,
            },
            {
                accessor: 'id',
                Header: 'Action',
                disableFilters: true,
                Cell: ({ cell }) => (
                    <>
                        <Link className='mx-2' to={`/customer/edit/${cell.row.values.id}`}>
                            <i className="far fa-edit text-primary"></i>
                        </Link>
                        {/* <Link className='' to={`/customer/delete/${cell.row.values.id}`}>
                            <i className="fas fa-trash-alt text-danger"></i>
                        </Link> */}
                    </>
                )
            },
        ],
        []
    )

    useEffect(() => {
        AxiosGet('customers').then(result => {
            setData(result.data.data);
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, []);
    // React-table end


    return (
        <>
            <ContentHeader
                h1='Customers'
                buttons={
                    [
                        { url: 'customer/add', label: 'Add Customer' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'customers', label: 'Customers', active: true },
                    ]
                }
            />
            <div className="row">
                <div className="col-12">
                    <CustomTable
                        columns={columns}
                        data={data}
                        height='auto'
                    />
                </div>
            </div>
        </>
    )
}
export default Customers;
