import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { AxiosGet, ComponentInput, ComponentSelect, ComponentTextarea, ContentHeader, handleSubmitForUpdate, onChange } from '../../Components/Components'

const EditPeople = () => {
    const params = useParams()
    const [dropdown, setDropdown] = useState('');
    const [modulesAccess, setModulesAccess] = useState({
        work_order: "",
        part: "",
        inventory: "",
        team: "",
        asset: "",
        people: "",
        category: "",
        location: "",
        checklist: "",
        vendor: "",
        customer: "",
    });
    const [selectedTeam, setSelectedTeam] = useState('');
    const [error, setError] = useState([]);
    const [accessManager, setAccessManager] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");

    const accessDropdown = [
        {
            "value": 0,
            "label": "View (Level 1)"
        },
        {
            "value": 1,
            "label": "Add (Level 2)"
        },
        {
            "value": 2,
            "label": "Edit (Level 3)"
        },
        {
            "value": 3,
            "label": "Delete (Level 4)"
        }
    ];

    const [values, setValues] = useState({
        name: "",
        email: "",
        password: "",
        phone: "",
        job_title: "",
        team_id: "",
        hourly_rate: "",
        notes: "",
        user_id: "",
        status: "",
    });

    useEffect(() => {
        (async () => {
            const users = await AxiosGet('users-for-people?user=' + params.id);
            const teams = await AxiosGet('dropdown/teams');
            setDropdown({ users, teams });
            const fetchedData = await AxiosGet('people/' + params.id);
            setValues({
                name: fetchedData.data.data.name,
                email: fetchedData.data.data.email,
                phone: fetchedData.data.data.phone,
                job_title: fetchedData.data.data.job_title,
                team_id: fetchedData.data.data.team_id,
                hourly_rate: fetchedData.data.data.hourly_rate,
                notes: fetchedData.data.data.notes,
                user_id: fetchedData.data.data.user_id,
                status: fetchedData.data.data.status,
            });
            setSelectedTeam(fetchedData.data.team)
            // Getting Access
            AxiosGet('access/' + fetchedData.data.data.user_id).then(result => {
                setModulesAccess({
                    id:result.data.data[0].id,
                    work_order: result.data.data[0].work_order,
                    part: result.data.data[0].part,
                    inventory: result.data.data[0].inventory,
                    team: result.data.data[0].team,
                    asset: result.data.data[0].asset,
                    people: result.data.data[0].people,
                    category: result.data.data[0].category,
                    location: result.data.data[0].location,
                    checklist: result.data.data[0].checklist,
                    vendor: result.data.data[0].vendor,
                    customer: result.data.data[0].customer,
                })
                setAccessManager(true)
                // console.log(result.data.data)
            }).catch(err => {
                console.log(err.response, '----')
            })
        })();
    }, [params.id]);

    // useEffect(() => {
    //     AxiosGet('access/' + params.id).then(result => {
    //         setModulesAccess({
    //             work_order: result.data.data[0].work_order,
    //             part: result.data.data[0].part,
    //             inventory: result.data.data[0].inventory,
    //             team: result.data.data[0].team,
    //             asset: result.data.data[0].asset,
    //             people: result.data.data[0].people,
    //             category: result.data.data[0].category,
    //             location: result.data.data[0].location,
    //             checklist: result.data.data[0].checklist,
    //             vendor: result.data.data[0].vendor,
    //             customer: result.data.data[0].customer,
    //         })
    //         setAccessManager(true)
    //         // console.log(result.data.data)
    //     }).catch(err => {
    //         console.log(err.response, '----')
    //     })
    // }, [params.id]);

    return (
        <>
            <ContentHeader
                h1='Edit People'
                buttons={
                    [
                        { url: 'people', label: 'People' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'people', label: 'People', active: false },
                        { url: 'people/edit', label: 'Edit', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm'>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                        <button className="nav-link active" id="user-details-tab" data-bs-toggle="tab" data-bs-target="#user-details" type="button" role="tab" aria-controls="user-details" aria-selected="true">User Details</button>
                    </li>
                    {
                        accessManager &&
                        <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                            <button className="nav-link" id="user-access-tab" data-bs-toggle="tab" data-bs-target="#user-access" type="button" role="tab" aria-controls="user-access" aria-selected="false">User Access</button>
                        </li>
                    }

                    <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                        <button className="nav-link" id="reset-password-tab" data-bs-toggle="tab" data-bs-target="#reset-password" type="button" role="tab" aria-controls="reset-password" aria-selected="false">Reset Password</button>
                    </li>
                </ul>
                <div className="tab-content pt-3 pb-2 px-3" id="myTabContent">
                    <div className="tab-pane fade show active" id="user-details" role="tabpanel" aria-labelledby="user-details-tab">
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className="mb-3">
                                    <ComponentInput
                                        name='name'
                                        type='text'
                                        displayName='Full Name'
                                        className='form-control'
                                        value={values['name']}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        error={error}
                                    />
                                </div>
                                <div className="mb-3">
                                    <ComponentInput
                                        name='email'
                                        type='email'
                                        displayName='Email'
                                        className='form-control'
                                        value={values['email']}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        error={error}
                                    />
                                </div>
                                <div className="mb-3">
                                    <ComponentInput
                                        name='phone'
                                        type='text'
                                        displayName='Contact No.'
                                        className='form-control'
                                        value={values['phone']}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        error={error}
                                    />
                                </div>
                                <div className="mb-3">
                                    {
                                        dropdown.users &&
                                        <ComponentSelect
                                            name='user_id'
                                            displayName='User'
                                            className='form-select'
                                            options={dropdown.users.data.data}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            selected={values['user_id']}
                                            label='username'
                                            value='id'
                                            error={error}
                                        />
                                    }
                                </div>
                                <div className='mb-3'>
                                    <ComponentSelect
                                        name='status'
                                        displayName='Status'
                                        className='form-select'
                                        options={[
                                            { value: 1, label: 'Active' },
                                            { value: 0, label: 'Inactive' },
                                        ]}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        selected={values['status']}
                                        label='label'
                                        value='value'
                                        error={error}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='mb-3'>
                                    <label htmlFor='team_id' className='form-label fw-semibold'>
                                        Team
                                    </label>
                                    {dropdown.teams && selectedTeam !== '' &&
                                        <Select
                                            name='team_id'
                                            defaultValue={selectedTeam}
                                            onChange={setSelectedTeam}
                                            options={dropdown.teams.data}
                                            isClearable={true}
                                            isMulti
                                        />
                                    }
                                    <span id='team_id-error' className='error text-danger'>
                                        {error && error['team_id']}
                                    </span>
                                </div>
                                <div className="mb-3">
                                    <ComponentInput
                                        name='job_title'
                                        type='text'
                                        displayName='Job Title'
                                        className='form-control'
                                        value={values['job_title']}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        error={error}
                                    />
                                </div>
                                <div className="mb-3">
                                    <ComponentInput
                                        name='hourly_rate'
                                        type='text'
                                        displayName='hourly Rate'
                                        className='form-control'
                                        value={values['hourly_rate']}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        error={error}
                                    />
                                </div>
                                <div className="mb-3">
                                    <ComponentTextarea
                                        name='notes'
                                        type='text'
                                        displayName='Notes'
                                        className='form-control'
                                        value={values['notes']}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        error={error}
                                    />
                                </div>

                            </div>
                            <div className='col-12 pt-2 pb-4'>
                                <button onClick={
                                    (e) => {
                                        let teams = [];
                                        if (selectedTeam !== null && selectedTeam !== '') {
                                            selectedTeam.map((item) => {
                                                return (
                                                    teams.push(item.value)
                                                )
                                            });
                                        }
                                        handleSubmitForUpdate(
                                            e,
                                            'people/edit',
                                            {
                                                id: params.id,
                                                name: values.name,
                                                email: values.email,
                                                phone: values.phone,
                                                job_title: values.job_title,
                                                team_id: teams.toString(),
                                                hourly_rate: values.hourly_rate,
                                                notes: values.notes,
                                                user_id: values.user_id,
                                                status: values.status,
                                            },
                                            setError,
                                            setNotificationMessage
                                        )
                                    }
                                } className="btn btn-primary">Submit</button>
                                {notificationMessage}
                            </div>
                        </div>
                    </div>
                    {
                        accessManager &&
                        <div className="tab-pane fade" id="user-access" role="tabpanel" aria-labelledby="user-access-tab">
                            <div className='row'>
                                <div className='col-12'>
                                    <h5 className='pb-2'>
                                        Access Manager
                                    </h5>
                                </div>
                                <div className='col-md-6'>
                                    <div className="mb-3">
                                        {
                                            modulesAccess &&
                                            <ComponentSelect
                                                name='work_order'
                                                displayName='Work Order'
                                                className='form-select'
                                                options={accessDropdown}
                                                onChange={(e) => onChange(e, modulesAccess, setModulesAccess)}
                                                selected={modulesAccess.work_order}
                                                label='label'
                                                value='value'
                                                error={error}
                                                noOptionLabel={true}
                                            />
                                        }
                                    </div>
                                    <div className="mb-3">
                                        {
                                            modulesAccess &&
                                            <ComponentSelect
                                                name='part'
                                                displayName='Parts'
                                                className='form-select'
                                                options={accessDropdown}
                                                onChange={(e) => onChange(e, modulesAccess, setModulesAccess)}
                                                selected={modulesAccess.part}
                                                label='label'
                                                value='value'
                                                error={error}
                                                noOptionLabel={true}
                                            />
                                        }
                                    </div>
                                    <div className="mb-3">
                                        {
                                            modulesAccess &&
                                            <ComponentSelect
                                                name='team'
                                                displayName='Team'
                                                className='form-select'
                                                options={accessDropdown}
                                                onChange={(e) => onChange(e, modulesAccess, setModulesAccess)}
                                                selected={modulesAccess.team}
                                                label='label'
                                                value='value'
                                                error={error}
                                                noOptionLabel={true}
                                            />
                                        }
                                    </div>
                                    <div className="mb-3">
                                        {
                                            modulesAccess &&
                                            <ComponentSelect
                                                name='location'
                                                displayName='Location'
                                                className='form-select'
                                                options={accessDropdown}
                                                onChange={(e) => onChange(e, modulesAccess, setModulesAccess)}
                                                selected={modulesAccess.location}
                                                label='label'
                                                value='value'
                                                error={error}
                                                noOptionLabel={true}
                                            />
                                        }
                                    </div>
                                    <div className="mb-3">
                                        {
                                            modulesAccess &&
                                            <ComponentSelect
                                                name='customer'
                                                displayName='Customer'
                                                className='form-select'
                                                options={accessDropdown}
                                                onChange={(e) => onChange(e, modulesAccess, setModulesAccess)}
                                                selected={modulesAccess.customer}
                                                label='label'
                                                value='value'
                                                error={error}
                                                noOptionLabel={true}
                                            />
                                        }
                                    </div>
                                    <div className="mb-3">
                                        {
                                            modulesAccess &&
                                            <ComponentSelect
                                                name='checklist'
                                                displayName='Checklist'
                                                className='form-select'
                                                options={accessDropdown}
                                                onChange={(e) => onChange(e, modulesAccess, setModulesAccess)}
                                                selected={modulesAccess.checklist}
                                                label='label'
                                                value='value'
                                                error={error}
                                                noOptionLabel={true}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="mb-3">
                                        {
                                            modulesAccess &&
                                            <ComponentSelect
                                                name='asset'
                                                displayName='Asset'
                                                className='form-select'
                                                options={accessDropdown}
                                                onChange={(e) => onChange(e, modulesAccess, setModulesAccess)}
                                                selected={modulesAccess.asset}
                                                label='label'
                                                value='value'
                                                error={error}
                                                noOptionLabel={true}
                                            />
                                        }
                                    </div>
                                    <div className="mb-3">
                                        {
                                            modulesAccess &&
                                            <ComponentSelect
                                                name='inventory'
                                                displayName='Inventory'
                                                className='form-select'
                                                options={accessDropdown}
                                                onChange={(e) => onChange(e, modulesAccess, setModulesAccess)}
                                                selected={modulesAccess.inventory}
                                                label='label'
                                                value='value'
                                                error={error}
                                                noOptionLabel={true}
                                            />
                                        }
                                    </div>
                                    <div className="mb-3">
                                        {
                                            modulesAccess &&
                                            <ComponentSelect
                                                name='people'
                                                displayName='People'
                                                className='form-select'
                                                options={accessDropdown}
                                                onChange={(e) => onChange(e, modulesAccess, setModulesAccess)}
                                                selected={modulesAccess.people}
                                                label='label'
                                                value='value'
                                                error={error}
                                                noOptionLabel={true}
                                            />
                                        }
                                    </div>
                                    <div className="mb-3">
                                        {
                                            modulesAccess &&
                                            <ComponentSelect
                                                name='category'
                                                displayName='Category'
                                                className='form-select'
                                                options={accessDropdown}
                                                onChange={(e) => onChange(e, modulesAccess, setModulesAccess)}
                                                selected={modulesAccess.category}
                                                label='label'
                                                value='value'
                                                error={error}
                                                noOptionLabel={true}
                                            />
                                        }
                                    </div>
                                    <div className="mb-3">
                                        {
                                            modulesAccess &&
                                            <ComponentSelect
                                                name='vendor'
                                                displayName='Vendor'
                                                className='form-select'
                                                options={accessDropdown}
                                                onChange={(e) => onChange(e, modulesAccess, setModulesAccess)}
                                                selected={modulesAccess.vendor}
                                                label='label'
                                                value='value'
                                                error={error}
                                                noOptionLabel={true}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className='col-12 pt-2 pb-4'>
                                    <button onClick={
                                        (e) => {
                                            handleSubmitForUpdate(
                                                e,
                                                'access/edit',
                                                {
                                                    id: modulesAccess.id,
                                                    work_order: modulesAccess.work_order,
                                                    part: modulesAccess.part,
                                                    inventory: modulesAccess.inventory,
                                                    team: modulesAccess.team,
                                                    asset: modulesAccess.asset,
                                                    people: modulesAccess.people,
                                                    category: modulesAccess.category,
                                                    location: modulesAccess.location,
                                                    checklist: modulesAccess.checklist,
                                                    vendor: modulesAccess.vendor,
                                                    customer: modulesAccess.customer,
                                                },
                                                setError,
                                                setNotificationMessage
                                            )
                                        }
                                    } className="btn btn-primary">Update Access</button>
                                    {notificationMessage}
                                </div>
                            </div>
                        </div>
                    }

                    <div className="tab-pane fade" id="reset-password" role="tabpanel" aria-labelledby="reset-password-tab">
                        <div className='row'>
                            <div className='col-12 pt-2 pb-4'>
                                <button onClick={
                                    (e) => {
                                        handleSubmitForUpdate(
                                            e,
                                            'forgot-password',
                                            {
                                                email: values.email,
                                            },
                                            setError,
                                            setNotificationMessage
                                        )
                                    }
                                } className="btn btn-primary">Send password reset email</button>
                                {notificationMessage}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditPeople