import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { AxiosGet, ComponentInput, ComponentSelect, ComponentTextarea, ContentHeader, handleSubmitForUpdate, onChange, ComponentLoading } from '../../Components/Components'
import Select from 'react-select';
import Card from 'react-bootstrap/Card';

const EditInventory = () => {
    const params = useParams()
    const [error, setError] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [dropdown, setDropdown] = useState('');

    const [values, setValues] = useState({
        minimum_quantity: "",
        location_id: "",
        contact_id: "",
        visiblity: "",
        team_id: "",
        type: "",
        notes: "",
        part_id: "",
        part_name: "",
    });

    useEffect(() => {
        (async () => {
            const teams = await AxiosGet('dropdown/teams');
            const locations = await AxiosGet('dropdown/locations');
            const vendors = await AxiosGet('dropdown/vendors');
            setDropdown({ teams, locations, vendors });

            const result = await AxiosGet('inventory/' + params.id);
            setSelectedTeam(result.data.team);
            setValues({
                part_id: result.data.part[0].part_id,
                part_name: result.data.part[0].part_name,
                minimum_quantity: result.data.part[0].minimum_quantity,
                location_id: result.data.part[0].location_id,
                contact_id: result.data.part[0].contact_id,
                visiblity: result.data.part[0].visiblity,
                team_id: result.data.part[0].team_id,
                type: result.data.part[0].type,
                notes: result.data.part[0].notes,
                status: result.data.part[0].status,
            });
            console.log(result.data)
            setLoading(false);
        })();
    }, [params.id]);

    return (
        <>
            <ContentHeader
                h1='Edit inventory'
                buttons={
                    [
                        { url: 'inventory', label: 'All Inventory' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'inventories', label: 'Inventory', active: false },
                        { url: 'inventory/edit', label: 'Edit', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm'>
                <form>
                    {
                        !loading ?

                            <div className="row p-3">
                                <div className='col-sm-8'>
                                    <div className="mb-3">
                                        {dropdown &&
                                            <ComponentSelect
                                                name='location_id'
                                                displayName='Asset Location'
                                                className='form-select'
                                                options={dropdown.locations.data}
                                                onChange={(e) => onChange(e, values, setValues)}
                                                selected={values['location_id']}
                                                label='label'
                                                value='value'
                                                error={error}
                                            />

                                        }
                                    </div>
                                    <div className="mb-3">
                                        <ComponentInput
                                            name='minimum_quantity'
                                            type='text'
                                            displayName='Minimum Quantity'
                                            className='form-control'
                                            value={values['minimum_quantity']}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            error={error}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        {dropdown &&
                                            <ComponentSelect
                                                name='contact_id'
                                                displayName='Vendor'
                                                className='form-select'
                                                options={dropdown.vendors.data}
                                                onChange={(e) => onChange(e, values, setValues)}
                                                selected={values['contact_id']}
                                                label='label'
                                                value='value'
                                                error={error}
                                            />
                                        }
                                    </div>
                                    <div className="mb-3">
                                        <ComponentSelect
                                            name='type'
                                            displayName='Inventory Type'
                                            className='form-select'
                                            options={[
                                                { value: 1, label: 'Stock' },
                                                { value: 0, label: 'Non Stock' },
                                            ]}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            selected={values['type']}
                                            label='label'
                                            value='value'
                                            error={error}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <ComponentSelect
                                            name='visiblity'
                                            displayName='Visiblity'
                                            className='form-select'
                                            options={[
                                                { value: 0, label: 'Hidden' },
                                                { value: 1, label: 'Visible to all' },
                                                { value: 2, label: 'Visible to specific teams' },
                                            ]}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            selected={values['visiblity']}
                                            label='label'
                                            value='value'
                                            error={error}
                                        />
                                    </div>
                                    <div className='mb-3'>
                                        <label htmlFor='team_id' className='form-label'>
                                            Assigned Team
                                        </label>
                                        {dropdown &&
                                            (
                                                <Select
                                                    isDisabled={values.visiblity !== 2 && true}
                                                    name='team_id'
                                                    defaultValue={selectedTeam}
                                                    onChange={setSelectedTeam}
                                                    options={dropdown.teams.data}
                                                    isClearable={true}
                                                    isMulti
                                                />
                                            )}
                                        <span id='team_id-error' className='error text-danger'>
                                            {error && error['team_id']}
                                        </span>
                                    </div>

                                    <div className="mb-3">
                                        <ComponentTextarea
                                            name='notes'
                                            type='text'
                                            displayName='Notes'
                                            className='form-control'
                                            value={values['notes']}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            error={error}
                                        />
                                    </div>
                                </div>

                                <div className='col-sm-4 '>
                                    <Card>
                                        <Card.Header>Adding inventory for:</Card.Header>
                                        <Card.Body>
                                            <blockquote className="blockquote mb-0">
                                                <div className="row">
                                                    <div className="col-sm-5 fs-6">Part Id:</div>
                                                    <div className="col-sm-7 fs-6">{values.part_id}</div>
                                                    <div className="col-sm-5 fs-6">Part Name:</div>
                                                    <div className="col-sm-7 fs-6">{values.part_name}</div>
                                                </div>
                                            </blockquote>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-12">
                                    <button onClick={
                                        (e) => {
                                            let teams = [];
                                            if (selectedTeam !== null && selectedTeam !== '') {
                                                selectedTeam.map((item) => {
                                                    return(
                                                        teams.push(item.value)
                                                    )
                                                });
                                            }
                                            handleSubmitForUpdate(
                                                e,
                                                'inventory/edit',
                                                {
                                                    id: params.id,
                                                    part_id: values.part_id,
                                                    minimum_quantity: values.minimum_quantity,
                                                    location_id: values.location_id,
                                                    contact_id: values.contact_id,
                                                    visiblity: values.visiblity,
                                                    team_id: teams.toString(),
                                                    type: values.type,
                                                    notes: values.notes,
                                                },
                                                setError,
                                                setNotificationMessage
                                            )
                                        }
                                    } className="btn btn-primary">Submit</button>
                                    {notificationMessage}
                                </div>

                            </div>
                            : <ComponentLoading />
                    }
                </form>
            </div>
        </>
    )
}

export default EditInventory