import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { assetUrl, AxiosDelete, AxiosGet, AxiosPost, AxiosPut, ComponentInput, ComponentLoading, ComponentSelect, ComponentTextarea, ContentHeader, handleSubmitForUpdate, onChange, secondsToHms, titleCase, useAxios } from '../../Components/Components';
import Select from 'react-select';
import { useParams } from 'react-router';
import { CustomTable, SelectColumnFilter } from '../../Components/CustomTable/CustomReactTable';
import * as qs from 'qs';
import toast from 'react-simple-toasts';
import PdfPlaceholder from '../../Assets/Images/pdf_placeholder.png';
import ReadQr from '../../Components/QrCode/ReadQr';
// import { now } from 'lodash';


const EditWorkOrder = () => {
    const params = useParams()
    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(true);
    const [notificationMessage, setNotificationMessage] = useState('');

    // QR Scanned Part Value Start
    // const [qrScannedPartId1, setQrScannedPartId1] = useState([{
    //     id: "part_name",
    //     value: "Hello"
    // }]);
    const [qrScannedPartId, setQrScannedPartId] = useState([]);
    // QR Scanned Part Value End

    // Refetch counter start
    const [refetch, setRefetch] = useState(0);
    const [refetchWorkOrderParts, setRefetchWorkOrderParts] = useState(0);
    const [refetchWorkOrderDocuments, setRefetchWorkOrderDocuments] = useState(0);
    const [refetchWorkOrderChecklist, setRefetchWorkOrderChecklist] = useState(0);
    // Refetch counter end

    // Timer related states start
    const [totalTime, setTotalTime] = useState(0);
    const [time, setTime] = useState(0);
    const [timerOn, setTimerOn] = useState(false);
    // Timer related states end


    // Values for Axios Get and component data start
    const [dropdown, setDropdown] = useState('');
    const [inventory, setInventory] = useState('');
    const [workOrderParts, setWorkOrderParts] = useState('');
    const [selectedAsset, setSelectedAsset] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedPrimaryWorker, setSelectedPrimaryWorker] = useState([]);
    const [selectedAdditionalWorker, setSelectedAdditionalWorker] = useState('');
    const [selectedRows, setSelectedRows] = useState('');
    const [checklistItems, setChecklistItems] = useState([]);
    const [checklistTask, setChecklistTask] = useState([]);
    const [selectedChecklistId, setSelectedChecklistId] = useState('');

    const [checklistValues, setChecklistValues] = useState({
        description: "",
        type: "",
        options: "",
    });

    const [userDetail] = useAxios({
        axiosInstance: axios,
        method: 'GET',
        endPoint: 'user-detail',
    });

    const [values, setValues] = useState({
        due_date: '',
        title: '',
        description: '',
        checklist_id: '',
        asset_id: '',
        category_id: '',
        priority: '',
        status: '',
    });

    const [documentList, setDocumentList] = useState('');

    const [document, setDocument] = useState({
        name: '',
        description: '',
        document_path: '',
    });
    // Values for Axios Get and component data end


    //Fetch / getting data start
    useEffect(() => {
        AxiosGet('work-order/' + params.id).then(result => {
            // setValues(values => { values.status = result.data.data.status }); // this method does not work, but below used method works.
            setValues({ ...values, status: result.data.data.status }); // this method works, but above used method works.
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, [refetch, params.id]);

    useEffect(() => {
        AxiosGet('part-consumption/' + params.id).then(result => {
            setWorkOrderParts(result.data.data)
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, [refetchWorkOrderParts, params.id]);

    useEffect(() => {
        AxiosGet('documents/work-order/' + params.id).then(result => {
            setDocumentList(result.data.data)
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, [refetchWorkOrderDocuments, params.id]);

    useEffect(() => {
        AxiosGet('work-order-checklist/' + params.id).then(result => {
            setChecklistItems(result.data.data)
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, [refetchWorkOrderChecklist, params.id]);

    useEffect(() => {
        (async () => {
            const assignee = await AxiosGet('dropdown/assignee');
            const assets = await AxiosGet('dropdown/assets');
            const categories = await AxiosGet('dropdown/categories/2');
            const checklists = await AxiosGet('dropdown/checklists');
            setDropdown({ assignee, assets, categories, checklists });
            const inventory = await AxiosGet('part-consumption/list');
            setInventory(inventory.data.data);
            const result = await AxiosGet('work-order/' + params.id);
            // const workers = await AxiosGet('work-order-assignee/'+ params.id);
            // console.log(workers);
            setSelectedPrimaryWorker(result.data.worker[0]);
            setSelectedCategories(result.data.category);
            setSelectedAsset(result.data.asset);
            setSelectedAdditionalWorker(result.data.additional_worker);
            setTotalTime((result.data.total_time_consumed) * 60);
            setValues({
                due_date: result.data.data.due_date,
                title: result.data.data.title,
                description: result.data.data.description,
                checklist_id: result.data.data.checklist_id,
                asset_id: result.data.data.asset_id,
                category_id: result.data.data.category_id,
                priority: result.data.data.priority,
                status: result.data.data.status,
            });
            setLoading(false);
            // console.log(result);
        })();
    }, [params.id]);

    // Work order timer related functions start
    React.useEffect(() => {
        let interval = null;
        if (timerOn) {
            interval = setInterval(() => {
                setTime((prevTime) => prevTime + 1);
            }, 1000);
        } else if (!timerOn) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timerOn]);

    function startTimer(e) {
        setTimerOn(true)
        handleSubmitForUpdate(
            e,
            'work-order-assignee/time/edit',
            {
                work_order_id: params.id,
                status: 2,
            },
        )
        setRefetch(new Date().getTime())
        // console.log(refetch);
    }

    function stopTimer(e) {
        setTimerOn(false);
        let timeconsumed = time / 60;
        handleSubmitForUpdate(
            e,
            'work-order-assignee/time/edit',
            {
                work_order_id: params.id,
                people_id: userDetail.id,
                time_consumed: timeconsumed,
            },
        )
    }
    // Work order timer related functions end


    // Saving data from multiple componenet to backend start
    function saveWorkOrder(e) {
        // console.log(selectedPrimaryWorker);
        if (true) {
            AxiosPut('work-order/edit',
                {
                    id: params.id,
                    due_date: values.due_date,
                    title: values.title,
                    description: values.description,
                    checklist_id: values.checklist_id,
                    asset_id: selectedAsset.value,
                    category_id: selectedCategories.value,
                    status: values.status,
                    created_by: userDetail.id,
                    priority: values.priority,
                    additional_worker: selectedAdditionalWorker,
                    primary_worker: selectedPrimaryWorker,
                })
                .then(result => {
                    // console.log(result)
                    setError('')
                    toast('Work Order Updated!', {
                        position: 'bottom-right',
                        clickable: true,
                        clickClosable: true,
                        time: 3000,
                        className: 'mb-3',
                        render: message => <div className="mb-3 alert alert-success" role="alert">
                            {message}
                        </div>
                    })
                }).catch(err => {
                    console.log(err.response)
                    setNotificationMessage('')
                    setError(err.response.data.errors)
                    console.log(err.response, '----')
                })
        }
    }

    function addPartsInWorkOrder(inventory_id, cost) {
        AxiosPost('part-consumption/add',
            {
                'work_order_id': params.id,
                'inventory_id': inventory_id,
                'cost': cost,
                'quantity': 1
            },
        ).then(result => {
            setRefetchWorkOrderParts(new Date().getTime())
        }).catch(err => {
            toast('Part already added!', {
                position: 'bottom-right',
                clickable: true,
                clickClosable: true,
                time: 3000,
                render: message => <div className="mb-3 alert alert-danger" role="alert">
                    {message}
                </div>
            })
        })
    }

    function removePartsFromWorkOrder(id) {
        if (true) {
            var data = qs.stringify({
                'id': id
            });
            AxiosDelete('part-consumption/remove', data).then(result => {
                setRefetchWorkOrderParts(new Date().getTime())
            }).catch(err => {
                console.log(err.response, '----')
            })
        }
    }

    function updatePartQuantityInWorkOrder(id, quantity) {
        if (true) {
            // axios.put(apiUrl + 'part-consumption/edit',
            //     {
            //         id: id,
            //         quantity: quantity,
            //     },
            // )
            AxiosPut('part-consumption/edit',
                {
                    id: id,
                    quantity: quantity,
                },
            ).then(result => {
                setRefetchWorkOrderParts(new Date().getTime())
                toast('Quantity Updated!', {
                    position: 'bottom-right',
                    clickable: true,
                    clickClosable: true,
                    time: 3000,
                    render: message => <div style={{ zIndex: 99999999 }} className="py-1 mb-3 alert alert-success" role="alert">
                        {message}
                    </div>
                })
            }).catch(err => {
                console.log(err.response, '----')
            })
        }

    }

    const formdata = new FormData();
    function addImageInWorkOrder() {
        formdata.append("name", document.name);
        formdata.append("description", document.description);
        formdata.append("document_path", document.document_path);
        formdata.append("type", 4);
        formdata.append("relation_key", params.id);
        formdata.append("tag", 5);
        if (true) {
            AxiosPost('document/add',
                formdata
            ).then(result => {
                setDocument({
                    name: '',
                    document_path: ''
                })
                setError('')
                setRefetchWorkOrderDocuments(new Date().getTime())
            }).catch(err => {
                setError(err.response.data.errors)
                console.log(err.response, '----')
            })
        }
    }

    function removeDocumentFromWorkOrder(id) {
        if (true) {
            var data = qs.stringify({
                'id': id
            });
            AxiosDelete('document/remove', data).then(result => {
                setRefetchWorkOrderDocuments(new Date().getTime())
            }).catch(err => {
                console.log(err.response, '----')
            })
        }
    }

    // function addChecklistItem(e) {
    //     // let option = checklistValues.options
    //     // if (checklistValues.type === 1) {
    //     //     option = 'pass,fail,flag'
    //     // } else if (checklistValues.type === 5) {
    //     //     option = 'open,inprogress,complete'
    //     // }
    //     // AxiosPost('work-order-checklist/add',
    //     //     {
    //     //         'description': checklistValues.description,
    //     //         'work_order_id': params.id,
    //     //         'type': checklistValues.type,
    //     //         'options': option
    //     //     },
    //     // ).then(result => {
    //     //     setRefetchWorkOrderChecklist(new Date().getTime())
    //     //     setChecklistValues({
    //     //         'description': '',
    //     //         'type': '',
    //     //         'options': '',
    //     //     })
    //     //     setError('')
    //     // }).catch(err => {
    //     //     setError(err.response.data.errors)
    //     //     console.log(err.response, '----')
    //     // })
    // }

    function addChecklistTasks(e) {
        if (selectedChecklistId && selectedChecklistId !== '' && selectedChecklistId !== 0) {
            AxiosGet('checklist/items/' + selectedChecklistId.value).then(result => {
                if (typeof checklistItems.find(o => o.checklist_id === result.data.data[0].checklist_id) === "undefined") {
                    // Saving previous values in a new constant
                    const newStateData = [...checklistTask, ...result.data.data]

                    // updating the state
                    const valueChecklist = newStateData.map((item) => {
                        return { ...item, work_order_id: parseInt(params.id) };
                    });
                    // console.log(valueChecklist)
                    setError('')
                    AxiosPost('work-order-checklist/add',
                        {
                            tasks: valueChecklist
                        },
                    ).then(result => {
                        console.log(result)
                        setError('')
                        setRefetchWorkOrderChecklist(new Date().getTime())
                    }).catch(err => {
                        setError(err.response.data.errors)
                        console.log(err.response, '----')
                    })
                } else {
                    toast('Checklist items already added!', {
                        position: 'bottom-right',
                        clickable: true,
                        clickClosable: true,
                        time: 3000,
                        render: message => <div style={{ zIndex: 99999999 }} className="py-1 mb-3 alert alert-danger" role="alert">
                            {message}
                        </div>
                    })
                }
            }).catch(err => {
                console.log(err.response, '----')
            })
        } else {
            toast('Please select a checklist..', {
                position: 'bottom-right',
                clickable: true,
                clickClosable: true,
                time: 3000,
                render: message => <div style={{ zIndex: 99999999 }} className="py-1 mb-3 alert alert-danger" role="alert">
                    {message}
                </div>
            })
        }
        // console.log(checklistItems)
    }

    function updateChecklistItem(e, id, response) {
        onChange(e, checklistValues, setChecklistValues)
        AxiosPut('work-order-checklist/edit',
            {
                'id': id,
                'response': response,
            },
        ).then(result => {
            setError('')
            setRefetchWorkOrderChecklist(new Date().getTime())
        }).catch(err => {
            // setError(err.response.data.errors)
            console.log(err.response, '----')
        })
    }

    function removeItemsFromChecklist(id) {
        if (true) {
            var data = qs.stringify({
                'id': id
            });
            AxiosDelete('work-order-checklist/remove', data).then(result => {
                setRefetchWorkOrderChecklist(new Date().getTime())
            }).catch(err => {
                console.log(err.response, '----')
            })
        }
    }


    // Saving data from multiple componenet to backend start
    // React-table start
    function quantityInStock(row) {
        return Number(
            row.quantity_total - row.quantity_consumed
        )
    }

    const columns = React.useMemo(
        () => [
            {
                id: 'part_name',
                accessor: 'part_name',
                Header: 'Part',
            },
            {
                accessor: 'description',
                Header: 'Description',
            },
            {
                // accessor: 'in_stock',
                Header: 'Quantity',
                accessor: quantityInStock,
            },
            {
                accessor: 'location_name',
                Header: 'Location',
                Filter: SelectColumnFilter,
                // filter: 'includes',
            },
            {
                accessor: 'inventory_id',
                Header: 'Action',
                disableFilters: true,
                Cell: ({ cell }) => (
                    <button
                        className='btn btn-sm btn-primary'
                        onClick={() => addPartsInWorkOrder(cell.row.values.inventory_id, cell.row.original.avg_cost)}>
                        {/* {cell.row.values.part_name} */}
                        Add
                    </button>
                )
            },
        ],
        []
    )
    // React-table end


    return (
        <>
            <ContentHeader
                h1='Edit Work Order'
                buttons={[{ url: 'work-orders', label: 'Work Orders' }]}
                breadcrumbs={[
                    { url: '', label: 'Dashboard', active: false },
                    { url: 'work-orders', label: 'Work Orders', active: false },
                    { url: 'word-order/edit', label: 'Edit', active: true },
                ]}
            />
            {
                !loading ?
                    <>
                        <div className='border rounded-2 shadow-sm'>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                                    <button className="nav-link active" id="basic-details-tab" data-bs-toggle="tab" data-bs-target="#basic-details" type="button" role="tab" aria-controls="basic-details" aria-selected="true">Basic Details</button>
                                </li>
                                <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                                    <button className="nav-link" id="workorder-parts-tab" data-bs-toggle="tab" data-bs-target="#workorder-parts" type="button" role="tab" aria-controls="workorder-parts" aria-selected="false">Parts</button>
                                </li>
                                <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                                    <button className="nav-link" id="workorder-images-tab" data-bs-toggle="tab" data-bs-target="#workorder-images" type="button" role="tab" aria-controls="workorder-images" aria-selected="false">Images</button>
                                </li>
                                <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                                    <button className="nav-link" id="workorder-checklist-tab" data-bs-toggle="tab" data-bs-target="#workorder-checklist" type="button" role="tab" aria-controls="workorder-checklist" aria-selected="false">Checklist(s)</button>
                                </li>
                            </ul>
                            <div className="tab-content pt-3 pb-2 px-3" id="myTabContent">
                                <div className="tab-pane fade show active" id="basic-details" role="tabpanel" aria-labelledby="basic-details-tab">
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className='mb-3'>
                                                <ComponentInput
                                                    name='title'
                                                    type='text'
                                                    displayName='Title'
                                                    className='form-control'
                                                    value={values['title']}
                                                    onChange={(e) => onChange(e, values, setValues)}
                                                    error={error}
                                                />
                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor='category_id' className='form-label fw-semibold'>
                                                    Category
                                                </label>
                                                {dropdown &&
                                                    dropdown.categories !== '' &&
                                                    dropdown.categories !== null && (
                                                        <Select
                                                            name='category_id'
                                                            defaultValue={selectedCategories}
                                                            onChange={setSelectedCategories}
                                                            options={dropdown.categories.data}
                                                            isClearable={true}
                                                        />
                                                    )}
                                                <span id='category_id-error' className='error text-danger'>
                                                    {error && error['category_id']}
                                                </span>
                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor='asset_id' className='form-label fw-semibold'>
                                                    Asset
                                                </label>
                                                {dropdown &&
                                                    dropdown.assets !== '' &&
                                                    values.title !== null && (
                                                        <Select
                                                            name='asset_id'
                                                            defaultValue={selectedAsset}
                                                            onChange={setSelectedAsset}
                                                            options={dropdown.assets.data}
                                                            isClearable={true}
                                                        />
                                                    )}
                                                <span id='asset_id-error' className='error text-danger'>
                                                    {error && error['asset_id']}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='mb-3'>
                                                <ComponentTextarea
                                                    name='description'
                                                    type='text'
                                                    rows={8}
                                                    displayName='Description'
                                                    className='form-control'
                                                    value={values['description']}
                                                    onChange={(e) => onChange(e, values, setValues)}
                                                    error={error}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        userDetail && userDetail.role > 1 &&

                                        <div className='row border-top mt-3 pt-4'>
                                            <div className='col-sm-6'>

                                                <div className='mb-3'>
                                                    <label htmlFor='assignee_id' className='form-label fw-semibold'>
                                                        Primary Technician
                                                    </label>
                                                    {dropdown &&
                                                        dropdown.assignee !== '' &&
                                                        dropdown.assignee !== null && values.title && (
                                                            <Select
                                                                name='assignee_id'
                                                                defaultValue={selectedPrimaryWorker}
                                                                onChange={setSelectedPrimaryWorker}
                                                                options={dropdown.assignee.data}
                                                                isClearable={true}
                                                            />
                                                        )}
                                                    <span id='assignee_id-error' className='error text-danger'>
                                                        {error && error['assignee_id']}
                                                    </span>
                                                </div>
                                                <div className='mb-3'>
                                                    <label htmlFor='additional_assignee_id' className='form-label fw-semibold'>
                                                        Additional Technicians
                                                    </label>
                                                    {dropdown &&
                                                        dropdown.assignee !== '' &&
                                                        dropdown.assignee !== null && values.title && (
                                                            <Select
                                                                name='additional_assignee_id'
                                                                defaultValue={
                                                                    selectedAdditionalWorker
                                                                }
                                                                onChange={setSelectedAdditionalWorker}
                                                                options={dropdown.assignee.data}
                                                                isOptionDisabled={(option) =>
                                                                    option.value === selectedPrimaryWorker.value
                                                                }
                                                                isClearable={true}
                                                                isMulti
                                                            />
                                                        )}
                                                    <span id='additional_assignee_id-error' className='error text-danger'>
                                                        {error && error['additional_assignee_id']}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <div className='mb-3'>
                                                    <label htmlFor='priority' className='form-label fw-semibold'>
                                                        Priority
                                                    </label>
                                                    <div>
                                                        <input type="radio" value={1} className="btn-check" name="priority"
                                                            id="priority-low" autoComplete="off" defaultChecked={values.priority === 1 && true}
                                                            onChange={(e) => onChange(e, values, setValues)} />
                                                        <label className="btn btn-outline-primary me-2" htmlFor="priority-low">
                                                            Low
                                                        </label>

                                                        <input type="radio" value={2} className="btn-check" name="priority"
                                                            id="priority-medium" autoComplete="off" defaultChecked={values.priority === 2 && true}
                                                            onChange={(e) => onChange(e, values, setValues)} />
                                                        <label className="btn btn-outline-warning me-2" htmlFor="priority-medium">
                                                            Medium
                                                        </label>

                                                        <input type="radio" value={3} className="btn-check" name="priority"
                                                            id="priority-high" autoComplete="off" defaultChecked={values.priority === 3 && true}
                                                            onChange={(e) => onChange(e, values, setValues)} />
                                                        <label className="btn btn-outline-danger" htmlFor="priority-high">
                                                            High
                                                        </label>
                                                    </div>
                                                    <span id='priority-error' className='error text-danger'>
                                                        {error && error['priority']}
                                                    </span>
                                                </div>
                                                <div className='mb-3'>
                                                    <ComponentInput
                                                        name='due_date'
                                                        type='date'
                                                        displayName='Due Date'
                                                        className='form-control'
                                                        value={values['due_date']}
                                                        onChange={(e) => onChange(e, values, setValues)}
                                                        error={error}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="tab-pane fade" id="workorder-images" role="tabpanel" aria-labelledby="workorder-images-tab">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h5>Document / Images</h5>
                                            <div className="row gx-3 gy-2 align-items-end  pb-4 mt-2">
                                                <div className="col-auto">
                                                    <ComponentInput
                                                        name='name'
                                                        type='text'
                                                        displayName='Title'
                                                        className='form-control'
                                                        value={document['name']}
                                                        onChange={(e) => onChange(e, document, setDocument)}
                                                        error={error}
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <ComponentInput
                                                        name='description'
                                                        type='text'
                                                        displayName='Description'
                                                        className='form-control'
                                                        value={document['description']}
                                                        onChange={(e) => onChange(e, document, setDocument)}
                                                        error={error}
                                                    />
                                                </div>
                                                <div className="col-auto">
                                                    <label className="form-label fw-semibold" htmlFor="document_path">Document</label>
                                                    <input
                                                        type="file"
                                                        onChange={
                                                            (e) => setDocument({ ...document, [e.target.name]: e.target.files[0] })
                                                        }
                                                        name="document_path"
                                                        className="form-control"
                                                        id="document_path" />
                                                    <span id='document_path-error' className='error text-danger'>
                                                        {error && error['document_path']}
                                                    </span>
                                                </div>
                                                <div className="col-auto">
                                                    <button
                                                        className='btn btn-primary'
                                                        onClick={
                                                            () => addImageInWorkOrder()
                                                        }>
                                                        Add Image
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='row border-top mt-3 py-4 d-flex justify-content-md-start justify-content-center'>
                                                {
                                                    (!loading && documentList && documentList !== '') ?
                                                        documentList.map((item, i) => (
                                                            <div key={i} className='col-auto d-flex flex-column my-2'>
                                                                <div className="card shadow-sm" style={{ width: '14rem' }}>
                                                                    <img
                                                                        src={item.document_path.split('.').reverse()[0] === 'pdf' ? PdfPlaceholder : assetUrl + item.document_path}
                                                                        style={{ height: '150px', objectFit: 'cover' }}
                                                                        className="card-img-top"
                                                                        alt={item.name} />
                                                                    <div className="card-body py-2">
                                                                        <h6 className="card-text">{item.name}</h6>
                                                                        <p className="card-text" style={{ fontSize: '0.85rem' }}>
                                                                            {
                                                                                item.description !== '' && item.description !== null ?
                                                                                    item.description : 'No description available!'
                                                                            }
                                                                        </p>
                                                                        <div className='d-flex justify-content-between'>
                                                                            <span
                                                                                onClick={() => { removeDocumentFromWorkOrder(item.id) }}
                                                                                style={{ cursor: 'pointer' }}
                                                                                className="card-link">
                                                                                <i className='fas fa-trash-alt text-danger'></i>
                                                                            </span>
                                                                            <a href={assetUrl + item.document_path}
                                                                                target='_blank'
                                                                                rel="noreferrer"
                                                                                className="card-link text-decoration-none">
                                                                                View &nbsp;
                                                                                <i className="fas fa-external-link"></i>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                        :
                                                        <span>
                                                            No documents added yet...
                                                        </span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="workorder-checklist" role="tabpanel" aria-labelledby="workorder-checklist-tab">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h5>Checklist / Task(s)</h5>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='table-responsive'>
                                                <table className='table table-stripped table-hover'>
                                                    <thead>
                                                        <tr>
                                                            <th width='40%'>Description</th>
                                                            <th width='30%'>Options</th>
                                                            <th width='20%'>Response</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            checklistItems !== '' ?
                                                                checklistItems.map((item, i) => {
                                                                    // console.log(item.id)
                                                                    let response = '';
                                                                    if (item.response && item.response !== '' && item.response !== null) {
                                                                        response = item.response
                                                                    }
                                                                    // let type = '';
                                                                    let option = <input
                                                                        type='text'
                                                                        name='response'
                                                                        value={response}
                                                                        onChange={(e) => updateChecklistItem(e, item.id, e.target.value)}
                                                                        className='form-control' />;
                                                                    // let checkbox = [];
                                                                    if (item.type === 1) {
                                                                        // type = 'Inspection'
                                                                        option = <ComponentSelect
                                                                            name={'response' + item.id}
                                                                            noOptionLabel={true}
                                                                            className='form-select'
                                                                            options={[
                                                                                { value: 'pass', label: 'Pass' },
                                                                                { value: 'fail', label: 'Fail' },
                                                                                { value: 'flag', label: 'Flag' },
                                                                            ]}
                                                                            onChange={(e) => updateChecklistItem(e, item.id, e.target.value)}
                                                                            selected={item.response}
                                                                            label='label'
                                                                            value='value'
                                                                            error={error}
                                                                        />
                                                                    }
                                                                    if (item.type === 4) {
                                                                        // type = 'Boolean'

                                                                        if (item.response === 1) {
                                                                            response = 'Yes'
                                                                        } else if (item.response === 0) {
                                                                            response = 'No'
                                                                        }
                                                                        option = <ComponentSelect
                                                                            name={'response' + item.id}
                                                                            className='form-select'
                                                                            noOptionLabel={true}
                                                                            options={[
                                                                                { value: 0, label: 'No' },
                                                                                { value: 1, label: 'Yes' },
                                                                            ]}
                                                                            onChange={(e) => updateChecklistItem(e, item.id, e.target.value)}
                                                                            selected={item.response}
                                                                            label='label'
                                                                            value='value'
                                                                            error={error}
                                                                        />
                                                                    }
                                                                    if (item.type === 5) {
                                                                        // type = 'Status'
                                                                        if (item.response === 1) {
                                                                            response = 'Open'
                                                                        } else if (item.response === 2) {
                                                                            response = 'In Progress'
                                                                        } else if (item.response === 3) {
                                                                            response = 'On Hold'
                                                                        } else if (item.response === 4) {
                                                                            response = 'Complete'
                                                                        }
                                                                        option = <ComponentSelect
                                                                            name={'response' + item.id}
                                                                            className='form-select'
                                                                            noOptionLabel={true}
                                                                            options={[
                                                                                { value: 1, label: 'Open' },
                                                                                { value: 2, label: 'In Progress' },
                                                                                { value: 3, label: 'On Hold' },
                                                                                { value: 4, label: 'Complete' },
                                                                            ]}
                                                                            onChange={(e) => updateChecklistItem(e, item.id, e.target.value)}
                                                                            selected={item.response}
                                                                            label='label'
                                                                            value='value'
                                                                            error={error}
                                                                        />
                                                                    }
                                                                    return <tr key={i}>
                                                                        <td>{item.description}</td>
                                                                        <td>{option}</td>
                                                                        <td>{titleCase(response)}</td>
                                                                        <td>
                                                                            <span
                                                                                onClick={() => { removeItemsFromChecklist(item.id) }}
                                                                                style={{ cursor: 'pointer' }}>
                                                                                <i className='fas fa-trash-alt text-danger'></i>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                }) :
                                                                <tr>
                                                                    <td colSpan={4}>No items added yet...</td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row mt-3 py-2">
                                        <div className='col-12'>
                                            <h5>Add Checklist Item / Task</h5>
                                        </div>
                                    </div> */}
                                    <div className='row d-flex justify-content-start align-items-center pt-3'>
                                        <div className='col-md-8'>
                                            <div className='mb-3'>
                                                <label htmlFor='checklist_id' className='form-label fw-semibold'>
                                                    Add Checklist/Tasks
                                                </label>
                                                {dropdown &&
                                                    dropdown.checklists !== '' &&
                                                    dropdown.checklists !== null && (
                                                        <Select
                                                            name='checklist_id'
                                                            defaultValue={selectedChecklistId}
                                                            onChange={setSelectedChecklistId}
                                                            options={dropdown.checklists.data}
                                                            isClearable={true}
                                                        />
                                                    )}
                                                <span id='checklist_id-error' className='error text-danger'>
                                                    {error && error['checklist_id']}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <button
                                                onClick={
                                                    () => {

                                                        addChecklistTasks()
                                                    }
                                                }
                                                className='btn btn-primary mt-3 w-100'
                                            >
                                                Add
                                            </button>
                                            {notificationMessage}
                                        </div>
                                    </div>
                                    {/* <div className='row'>
                                        <div className="col-4">
                                            <div className="mb-3">
                                                <ComponentInput
                                                    name='description'
                                                    type='text'
                                                    displayName='Description'
                                                    className='form-control'
                                                    value={checklistValues['description']}
                                                    onChange={(e) => onChange(e, checklistValues, setChecklistValues)}
                                                    error={error}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="mb-3">
                                                <ComponentSelect
                                                    name='type'
                                                    displayName='Type'
                                                    className='form-select'
                                                    options={[
                                                        { value: 1, label: 'Inspection' },
                                                        { value: 2, label: 'Number' },
                                                        { value: 3, label: 'Text' },
                                                        { value: 4, label: 'Boolean (Yes/No)' },
                                                        { value: 5, label: 'Status' },
                                                    ]}
                                                    onChange={(e) => onChange(e, checklistValues, setChecklistValues)}
                                                    selected={checklistValues['type']}
                                                    label='label'
                                                    value='value'
                                                    error={error}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-3 d-none">
                                            <div className="mb-3">
                                                <ComponentTextarea
                                                    name='options'
                                                    displayName='Options'
                                                    className='form-control'
                                                    value={checklistValues['options']}
                                                    onChange={(e) => onChange(e, checklistValues, setChecklistValues)}
                                                    error={error}
                                                    disabled={true}
                                                    // disabled={checklistValues.type !== 4 ? true : false}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-auto mt-3'>
                                            <button onClick={
                                                (e) => {
                                                    addChecklistItem(e)
                                                }
                                            } className="btn btn-primary mt-3">Add Checklist Item</button>
                                            {notificationMessage}
                                        </div>

                                    </div> */}
                                </div>
                                <div className="tab-pane fade" id="workorder-parts" role="tabpanel" aria-labelledby="workorder-parts-tab">
                                    <div className='row'>
                                        <div className='col-md-7'>
                                            <div className='row border mx-2 my-2 py-3 shadow-sm'>
                                                <div className="d-flex flex-row justify-content-between pb-2">
                                                    <div className="">
                                                        <h5>Search Parts</h5>
                                                    </div>
                                                    <div className="">
                                                        <ReadQr setQrScannedPartId={setQrScannedPartId} id='part_name' />
                                                    </div>
                                                </div>
                                                {inventory &&
                                                    <CustomTable
                                                        columns={columns}
                                                        data={inventory}
                                                        height='auto'
                                                        selectedRows={selectedRows}
                                                        onSelectedRowsChange={setSelectedRows}
                                                        filterData={qrScannedPartId}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className='col-md-5'>
                                            <div className='row border mx-2 my-2 py-3 shadow'>
                                                <h5>Added Parts</h5>
                                                <div className='table-responsive'>
                                                    <table className='table table-stripped table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Part</th>
                                                                <th>Qty</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                !loading && workOrderParts && workOrderParts !== '' ?
                                                                    workOrderParts.map((item, i) => {
                                                                        if (item.inventory) {
                                                                            return <tr key={i}>
                                                                                {/* {console.log(item.inventory)} */}
                                                                                <td>
                                                                                    {item.inventory.part.name} &nbsp;
                                                                                    <small className='x-small'>
                                                                                        ({item.inventory.location.name})
                                                                                    </small>
                                                                                </td>
                                                                                <td width={100}>
                                                                                    <input
                                                                                        value={item.quantity}
                                                                                        className='form-control py-0'
                                                                                        type='number'
                                                                                        min={1}
                                                                                        onChange={(e) => updatePartQuantityInWorkOrder(item.id, e.target.value)} />
                                                                                </td>
                                                                                <td width={30}>
                                                                                    <span onClick={() => { removePartsFromWorkOrder(item.id) }} style={{ cursor: 'pointer' }}>
                                                                                        <i className='fas fa-trash-alt text-danger'></i>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        } else {
                                                                            return null
                                                                        }
                                                                    }) :
                                                                    <tr>
                                                                        <td colSpan={3}>No parts added yet...</td>
                                                                    </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='border rounded-2 shadow-sm mt-3'>
                            <div className='row py-3 px-3'>
                                <div className='col-md-9'>
                                    <div className='mb-3'>
                                        <label htmlFor='status' className='form-label fw-semibold'>
                                            Status
                                        </label>
                                        <div>
                                            <input type="radio" value={1} className="btn-check" name="status" id="status-open" checked={values.status === 1} onChange={(e) => { setValues({ ...values, status: 1 }) }} />
                                            <label className="btn btn-outline-primary me-2 mb-2" htmlFor="status-open">
                                                Open
                                            </label>

                                            <input type="radio" value={2} className="btn-check" name="status" id="status-inprogress" checked={values.status === 2} onChange={(e) => { setValues({ ...values, status: 2 }) }} />
                                            <label className="btn btn-outline-warning me-2 mb-2" htmlFor="status-inprogress">
                                                In-progress
                                            </label>

                                            <input type="radio" value={3} className="btn-check" name="status" id="status-onhold" checked={values.status === 3} onChange={(e) => { setValues({ ...values, status: 3 }) }} />
                                            <label className="btn btn-outline-danger me-2 mb-2" htmlFor="status-onhold">
                                                On hold
                                            </label>

                                            <input type="radio" value={4} className="btn-check" name="status" id="status-complete" checked={values.status === 4} onChange={(e) => { setValues({ ...values, status: 4 }) }} />
                                            <label className="btn btn-outline-dark me-2 mb-2" htmlFor="status-complete">
                                                Complete
                                            </label>
                                        </div>
                                        <span id='status-error' className='error text-danger'>
                                            {error && error['status']}
                                        </span>
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    {
                                        ((selectedPrimaryWorker && selectedPrimaryWorker.value === userDetail.id) || selectedAdditionalWorker.find((i) => i.value === userDetail.id)) &&
                                        <div className='mb-3'>
                                            <div className="Timers">
                                                <label htmlFor='timer' className='form-label fw-semibold'>
                                                    Time
                                                </label>
                                                <div className='d-flex1 justify-content-start align-items-center'>
                                                    <div className='me-41'>
                                                        {
                                                            !timerOn && time === 0 && (
                                                                <button className='btn btn-success w-100' onClick={(e) => startTimer(e)}>
                                                                    Start Work
                                                                </button>
                                                            )
                                                        }
                                                        {
                                                            timerOn &&
                                                            <button className='btn btn-primary w-100' onClick={(e) => stopTimer(e)}>
                                                                Stop Work
                                                            </button>
                                                        }
                                                        {
                                                            !timerOn && time > 0 && (
                                                                <button className='btn btn-success w-100' onClick={(e) => setTimerOn(true)}>Resume Work</button>
                                                            )
                                                        }
                                                    </div>
                                                    <div className={`d-flex justify-content-center align-items-center badge fs-5 fw-normal text-dark shadow w-100 py-2 ${timerOn ? `bg-light` : `bg-light`}`}>
                                                        {secondsToHms(totalTime + time)}
                                                        {timerOn && <div className="ms-4 me-2 spinner" />}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className='col-12 pt-3'>
                                    <button
                                        onClick={
                                            (e) => {

                                                saveWorkOrder(e)
                                            }
                                        }
                                        className='btn btn-primary'
                                    >
                                        Submit
                                    </button>
                                    {notificationMessage}
                                </div>
                            </div>
                        </div>
                    </>
                    : <ComponentLoading />}
        </>
    );
};

export default EditWorkOrder;