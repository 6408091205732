import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AxiosGet, ComponentLabelValueRow, ContentHeader } from '../../Components/Components'
// import AgTable, { Action, Show } from '../../Components/CustomTable/AgTable';
import { CustomTable } from '../../Components/CustomTable/CustomReactTable';

const ShowLocation = () => {

    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [values, setValues] = useState({
        name: "",
        address: "",
        map_address: "",
        has_parent: false,
        parent_location_id: "",
        notes: "",
    });


    useEffect(() => {
        (async () => {
            const result = await AxiosGet('location/' + params.id);
            // console.log(result)
            setValues({
                name: result.data.data.name,
                address: result.data.data.address,
                map_address: result.data.data.map_address,
                has_parent: (result.data.data.has_parent !== 0) ? true : false,
                parent_location_id: (result.data.data.has_parent !== 0) ? result.data.data.parent_location_id.id : '',
                notes: result.data.data.notes,
            });
            setLoading(false);
        })();
    }, [params.id]);

    // React-table start
    const [data, setData] = useState([]);

    const columns = React.useMemo(
        () => [
            {
                accessor: 'name',
                Header: 'Location Name',
                Cell: ({ cell }) => (
                    <>
                        <Link className='mx-2' to={`/location/view/${cell.row.values.id}`}>
                            {cell.value}
                        </Link>
                    </>
                ),
            },
            {
                accessor: 'address',
                Header: 'Physical Address'
            },
            {
                accessor: 'map_address',
                Header: 'Google Map Link',
                Cell: ({ cell }) => {
                    if (cell.value === null || cell.value === '') {
                        return 'N/A';
                    } else {
                        return cell.value;
                    }

                },
            },
            {
                accessor: 'id',
                Header: 'Action',
                disableFilters: true,
                disableSortBy: true,
                Cell: ({ cell }) => (
                    <>
                        <Link className='mx-2' to={`/location/edit/${cell.row.values.id}`}>
                            <i className="far fa-edit text-primary"></i>
                        </Link>
                        <Link className='' to={`/location/delete/${cell.row.values.id}`}>
                            <i className="fas fa-trash-alt text-danger"></i>
                        </Link>
                    </>
                ),
            },
        ],
        []
    )

    useEffect(() => {
        AxiosGet('locations?parent_location_id[eq]=' + params.id).then(result => {
            setData(result.data.data);
            // console.log(result.data.data)
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, [params.id]);
     // React-table end

    return (
        <>
            <ContentHeader
                h1='Location Details'
                buttons={
                    [
                        { url: 'location/edit/' + params.id, label: 'Edit Location' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'locations', label: 'Locations', active: false },
                        { url: 'location/' + params.id, label: 'Location Details', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm px-3 py-3'>
                {
                    (!loading && values !== '') ?
                        <>
                            <ComponentLabelValueRow
                                label='Location Name'
                                value={values.name}
                            />
                            <ComponentLabelValueRow
                                label='Address'
                                value={values.address}
                            />
                            <ComponentLabelValueRow
                                label='Google map link'
                                value={values.map_address}
                            />
                            <ComponentLabelValueRow
                                label='Notes'
                                value={values.notes}
                            />
                            <div className='row pt-4 px-1'>
                                <div className='col-12'>
                                    <h6 className=''>Child Locations</h6>
                                </div>
                                <div className='col-12'>
                                    <CustomTable
                                        columns={columns}
                                        data={data}
                                        height='auto'
                                    />
                                    {/* <AgTable endpoint={'locations?parent_location_id[eq]=' + params.id} columnDefs={columnDefs} height='450px' refetch={params.id} /> */}
                                </div>
                            </div>
                        </> :

                        'loading...'
                }
            </div>

        </>
    );
};
export default ShowLocation
