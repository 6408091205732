import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { apiUrl, AxiosGet, AxiosPost, AxiosPut, bearerToken, ComponentLabelValueRow, ContentHeader, useAxios } from '../../Components/Components'
import NotificationMessage from '../../Components/NotificationMessage';
import Popup from '../../Components/Popup';
import { CustomTable } from '../../Components/CustomTable/CustomReactTable';



const ShowInventoryDetail = () => {

    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [updateStockPopup, setUpdateStockPopup] = useState(false)
    const [transferStockPopup, setTransferStockPopup] = useState(false)
    const [error, setError] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [refetch, setRefetch] = useState(0);
    const [remainingQuantity, setremainingQuantity] = useState('')

    const [inventoryDetails, setInventoryDetails] = useState({
        id: "",
        name: "",
        category: "",
        location_name: "",
        description: "",
        quantity_total: "",
        quantity_consumed: "",
        stock_status: "",
        minimum_quantity: "",
        company_name: "",
        type: "",
        visiblity: "",
        notes: "",
        inventory_id: "",
    });

    const [values, setValues] = useState({
        cost: "",
        quantity: "",
        notes: "",
    });

    const [stockTrasferValues, setStockTrasferValues] = useState({
        inventory_id: "", // actually location Id
        inventory_detail_id: "",
        quantity_transfer: 0,
        cost: "",
        notes: "",
    });

    // const tableRowActionClick = (rowData) => {
    //     // console.log(rowData['id'])
    //     setStockTrasferValues({ ...stockTrasferValues, cost: rowData['cost'], inventory_detail_id: rowData['id'] })
    //     setremainingQuantity(rowData['quantity_remaining'])
    //     setTransferStockPopup(true)
    // }

    const tableRowActionClick = useCallback((rowData) => {
        // console.log(rowData['id'])
        setStockTrasferValues({ ...stockTrasferValues, cost: rowData['cost'], inventory_detail_id: rowData['id'] })
        setremainingQuantity(rowData['quantity_remaining'])
        setTransferStockPopup(true)
    }, [stockTrasferValues]
    )

    const handleSubmitForUpdatingStock = (event) => {
        event.preventDefault();
        if (true) {
            AxiosPost('inventory/stock/add', {
                inventory_id: params.id,
                cost: values.cost,
                quantity: values.quantity,
                quantity_remaining: values.quantity,
                notes: values.notes,
            }).then(result => {
                setValues({
                    cost: "",
                    quantity: "",
                    notes: "",
                });
                setUpdateStockPopup(false)
                setRefetch(new Date().getTime())
                setError('')
                setNotificationMessage(<NotificationMessage type="success" message={result.data.message} />)
            }).catch(err => {
                setNotificationMessage('')
                setError(err.response.data.errors)
                console.log(err.response, '----')
            })
        }
    }

    const handleSubmitForTransfer = (event) => {
        event.preventDefault();
        // console.log(stockTrasferValues)
        if (true) {
            AxiosPut('inventory/stock/transfer', {
                id: stockTrasferValues.inventory_detail_id,
                inventory_id: stockTrasferValues.inventory_id,
                cost: stockTrasferValues.cost,
                quantity: stockTrasferValues.quantity_transfer,
                notes: stockTrasferValues.notes,
            })
                .then(result => {
                    setStockTrasferValues({
                        inventory_id: "",
                        inventory_detail_id: "",
                        quantity_transfer: 0,
                        cost: "",
                        notes: "",
                    });
                    setTransferStockPopup(false)
                    setRefetch(new Date().getTime())
                    setError('')
                    // console.log(result)
                    setNotificationMessage(<NotificationMessage type="success" message={result.data.message} />)
                }).catch(err => {
                    setNotificationMessage('')
                    setError(err.response.data.errors)
                    console.log(err.response, '----')
                })
        }
    }

    const onChange = (e) => {
        if (e.target.type === 'checkbox') {
            setValues({ ...values, [e.target.name]: !values[e.target.name] })

        } else {
            setValues({ ...values, [e.target.name]: e.target.value });
        }
    };

    useEffect(() => {
        axios.get(apiUrl + 'inventory/' + params.id, {
            headers: {
                'Accept': 'application/json',
                'Authorization': bearerToken
            }
        }).then(result => {
            setInventoryDetails({
                id: result.data.part[0].part_id,
                inventory_id: result.data.part[0].inventory_id,
                name: result.data.part[0].part_name,
                category: result.data.part[0].category_name,
                location_name: result.data.part[0].location_name,
                description: result.data.part[0].description,
                quantity_total: result.data.part[0].quantity_total,
                quantity_consumed: result.data.part[0].quantity_consumed,
                stock_status: result.data.part[0].stock_status,
                minimum_quantity: result.data.part[0].minimum_quantity,
                company_name: result.data.part[0].company_name,
                type: result.data.part[0].type,
                visiblity: result.data.part[0].visiblity,
                notes: result.data.part[0].notes,
            });
            setLoading(false)
            // console.log(result.data)
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, [refetch, params.id]);

    const [inventory] = useAxios({
        axiosInstance: axios,
        method: 'GET',
        endPoint: 'inventory/related/' + params.id,
    });

    // React-table start
    const [data, setData] = useState([]);

    const columns = React.useMemo(
        () => [
            {
                accessor: 'cost',
                Header: 'Cost',
            },
            {
                accessor: 'quantity_remaining',
                Header: 'Quantity Instock',
            },
            {
                accessor: 'notes',
                Header: 'Notes',

            },
            {
                accessor: 'updated_at_human_readable',
                Header: 'Updated at',

            },
            {
                accessor: 'id',
                Header: 'Action',
                disableFilters: true,
                disableSortBy: true,
                Cell: ({ cell }) => (
                    <>
                        {
                            (cell.row.values.quantity_remaining > 0) &&

                            <button type='button' className='btn btn-sm btn-primary' onClick={() => tableRowActionClick(cell.row.values)}>
                                Transfer
                            </button>
                        }
                    </>
                )
            },
        ],
        [tableRowActionClick]
    )

    useEffect(() => {
        AxiosGet('inventory/' + params.id).then(result => {
            setData(result.data.data);
            // console.log(result.data.data)
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, [refetch, params.id]);
    // React-table end

    return (
        <>
            <ContentHeader
                h1='Inventory Details'
                buttons={
                    [
                        { url: 'inventory/edit/' + params.id, label: 'Edit Inventory' },
                        // { url: 'inventory/stock/add', label: 'Add Stock'},
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'parts', label: 'Parts', active: false },
                        { url: 'inventories', label: 'Inventory', active: false },
                        { url: 'inventory', label: 'Inventory Details', active: true },
                    ]
                }
            />
            <div className="row">
                <div className="col-12">
                    {/* /.card */}
                    <div className="card ">

                        <div className="card-body">
                            {
                                (!loading && inventoryDetails !== '') ?
                                    <div className='row'>
                                        {/* {console.log(inventoryDetails)} */}
                                        <div className='col-sm-6'>
                                            <ComponentLabelValueRow
                                                label='Part Name'
                                                value={<Link to={'/part/detail/' + inventoryDetails.id}> {inventoryDetails.name}</Link>}
                                            />
                                            <ComponentLabelValueRow
                                                label='Location'
                                                value={inventoryDetails.location_name}
                                            />
                                            <ComponentLabelValueRow
                                                label='Vendor'
                                                value={inventoryDetails.company_name}
                                            />
                                            <ComponentLabelValueRow
                                                label='Inventory Type'
                                                value={inventoryDetails.type === 0 ? 'Non-Stock' : 'Stock'}
                                            />
                                            <ComponentLabelValueRow
                                                label='Notes'
                                                value={inventoryDetails.notes}
                                            />
                                        </div>
                                        <div className='col-sm-6'>

                                            <ComponentLabelValueRow
                                                label='Minimum Quantity'
                                                value={inventoryDetails.minimum_quantity}
                                            />
                                            <ComponentLabelValueRow
                                                label='Total Stock'
                                                value={inventoryDetails.quantity_total}
                                            />
                                            <ComponentLabelValueRow
                                                label='Total Consumed'
                                                value={inventoryDetails.quantity_consumed}
                                            />
                                            {
                                                (inventoryDetails.stock_status && inventoryDetails.stock_status.includes("In")) &&
                                                <ComponentLabelValueRow
                                                    label='Total Consumed'
                                                    value={<span className={'badge bg-success'}>{inventoryDetails.stock_status}</span>}
                                                />
                                            }
                                            {
                                                (inventoryDetails.stock_status && inventoryDetails.stock_status.includes("Low")) &&
                                                <ComponentLabelValueRow
                                                    label='Total Consumed'
                                                    value={<span className={'badge bg-warning'}>{inventoryDetails.stock_status}</span>}
                                                />
                                            }
                                            {
                                                (inventoryDetails.stock_status && inventoryDetails.stock_status.includes("Out")) &&
                                                <ComponentLabelValueRow
                                                    label='Total Consumed'
                                                    value={<span className={'badge bg-danger'}>{inventoryDetails.stock_status}</span>}
                                                />
                                            }
                                            <ComponentLabelValueRow
                                                label=''
                                                value={
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-primary float-right"
                                                        onClick={() => setUpdateStockPopup(true)}>
                                                        Update Stock
                                                    </button>
                                                }
                                            />
                                        </div>
                                    </div>
                                    : 'loading...'
                            }

                            <div className='row pt-4'>
                                <div className='col-12'>
                                    <CustomTable
                                        columns={columns}
                                        data={data}
                                        height='auto'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* /.card */}
                </div>
                {/* /.col */}
            </div>

            {/* Update stock */}
            <Popup trigger={updateStockPopup}>
                <div className="card">
                    <div className="card-header py-3 px-4 d-flex justify-content-between align-items-center">
                        <h4 className="card-title pt-2">Update Stock</h4>
                        <button
                            type="button"
                            className="btn btn-xs btn-primary close"
                            aria-label="Close"
                            onClick={() => setUpdateStockPopup(false)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="card-body px-4">
                        <div className="form-group mb-3">
                            <label htmlFor="quantity" className='fw-semibold'>Quantity</label>
                            <input
                                name='quantity'
                                type="text"
                                className="form-control"
                                id="quantity"
                                placeholder="Enter quantity received"
                                value={values['quantity']}
                                onChange={onChange}
                            />
                            <span id="quantity-error" className="error text-danger">
                                {(error) && error['quantity']}
                            </span>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="cost" className='fw-semibold'>Cost</label>
                            <input
                                name='cost'
                                type="text"
                                className="form-control"
                                id="cost"
                                placeholder="Enter quantity for low stock notifications"
                                value={values['cost']}
                                onChange={onChange}
                            />
                            <span id="cost-error" className="error text-danger">
                                {(error) && error['cost']}
                            </span>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="notes" className='fw-semibold'>Notes</label>
                            <textarea
                                name='notes'
                                type="text"
                                className="form-control"
                                id="notes"
                                placeholder="Add notes..."
                                value={values['notes']}
                                onChange={onChange}
                            />
                            <span id="notes-error" className="error text-danger">
                                {(error) && error['notes']}
                            </span>
                        </div>
                    </div>
                    <div className="card-footer py-3 px-4 justify-content-between">
                        <button onClick={handleSubmitForUpdatingStock} type="button" className="btn btn-primary">Submit</button>
                        {notificationMessage}
                    </div>
                </div>
            </Popup>

            {/* Transfer stock */}
            <Popup trigger={transferStockPopup}>
                <div>
                    <div className="card">
                        <div className="card-header py-3 px-4 d-flex justify-content-between align-items-center">
                            <h4 className="card-title pt-2">Transfer Stock</h4>
                            <button
                                type="button"
                                className="btn btn-xs btn-primary close"
                                aria-label="Close"
                                onClick={() => setTransferStockPopup(false)}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="card-body px-4">
                            <div className="form-group mb-3">
                                <label htmlFor="quantity_transfer" className='fw-semibold'>Transfer</label>
                                <input
                                    name='quantity_transfer'
                                    type="text"
                                    className="form-control"
                                    id="quantity_transfer"
                                    placeholder="Enter quantity to transfer"
                                    value={stockTrasferValues['quantity_transfer']}
                                    onChange={(e) => {

                                        (e.target.value <= remainingQuantity) ?
                                            setStockTrasferValues({ ...stockTrasferValues, [e.target.name]: e.target.value }) :
                                            setStockTrasferValues({ ...stockTrasferValues, [e.target.name]: remainingQuantity })

                                    }}
                                />
                                <small className='d-block pt-1'>Quantity Remaining: {(remainingQuantity - (isNaN(stockTrasferValues['quantity_transfer']) ? 0 : stockTrasferValues['quantity_transfer']))}</small>
                                <span id="quantity_transfer-error" className="error text-danger">
                                    {(error) && error['quantity']}
                                </span>
                            </div>
                            <div className="form-group mb-3">
                                <label className='fw-semibold'>Select Location</label>
                                <select
                                    id='inventory_id'
                                    name='inventory_id'
                                    className="form-control"
                                    required
                                    value={stockTrasferValues['inventory_id']}
                                    onChange={(e) => {
                                        setStockTrasferValues({ ...stockTrasferValues, [e.target.name]: e.target.value });
                                        // setStockTrasferValues({ inventory_id: e.target.value })
                                    }}
                                >
                                    <option>Select Location</option>
                                    {
                                        (inventory && (inventory !== '' || inventory !== null)) &&
                                        inventory.map((item, i) =>
                                            <option value={item.id} key={i}>{item.name}</option>
                                        )
                                    }
                                </select>
                                <span id="inventory_id-error" className="error text-danger">
                                    {(error) && error['inventory_id']}
                                </span>
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="notes" className='fw-semibold'>Notes</label>
                                <textarea
                                    name='notes'
                                    type="text"
                                    className="form-control"
                                    id="notes"
                                    placeholder="Add notes..."
                                    value={stockTrasferValues['notes']}
                                    onChange={(e) => {
                                        setStockTrasferValues({ ...stockTrasferValues, [e.target.name]: e.target.value })
                                    }}
                                />
                                <span id="notes-error" className="error text-danger">
                                    {(error) && error['notes']}
                                </span>
                            </div>
                        </div>
                        <div className="card-footer justify-content-between py-3 px-4">
                            <button
                                onClick={handleSubmitForTransfer}
                                type="button"
                                className="btn btn-primary"
                                // disabled={true}
                                disabled={
                                    (stockTrasferValues.inventory_id === ''
                                        || stockTrasferValues.inventory_id === null
                                        || stockTrasferValues.quantity < 1) && true
                                }
                            >Submit</button>
                            {notificationMessage}
                        </div>
                    </div>

                </div>
                {/* <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Transfer Stock</h4>
                                <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={() => setTransferStockPopup(false)}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="quantity_transfer">Transfer</label>
                                    <input
                                        name='quantity_transfer'
                                        type="text"
                                        className="form-control"
                                        id="quantity_transfer"
                                        placeholder="Enter quantity to transfer"
                                        value={stockTrasferValues['quantity_transfer']}
                                        onChange={(e) => {
                                            {
                                                (e.target.value <= remainingQuantity) ?
                                                    setStockTrasferValues({ ...stockTrasferValues, [e.target.name]: e.target.value }) :
                                                    setStockTrasferValues({ ...stockTrasferValues, [e.target.name]: remainingQuantity })
                                            }
                                        }}
                                    />
                                    <small className='d-block'>Quantity Remaining: {(remainingQuantity - (isNaN(stockTrasferValues['quantity_transfer']) ? 0 : stockTrasferValues['quantity_transfer']))}</small>
                                    <span id="quantity_transfer-error" className="error text-danger">
                                        {(error) && error['quantity']}
                                    </span>
                                </div>
                                <div className="form-group">
                                    <label>Select Location</label>
                                    <select
                                        id='inventory_id'
                                        name='inventory_id'
                                        className="form-control"
                                        required
                                        value={stockTrasferValues['inventory_id']}
                                        onChange={(e) => {
                                            setStockTrasferValues({ ...stockTrasferValues, [e.target.name]: e.target.value });
                                            // setStockTrasferValues({ inventory_id: e.target.value })
                                        }}
                                    >
                                        <option>Select Location</option>
                                        {
                                            (inventory && (inventory != '' || inventory != null)) &&
                                            inventory.map((item, i) =>
                                                <option value={item.id}>{item.name}</option>
                                            )
                                        }
                                    </select>
                                    <span id="inventory_id-error" className="error text-danger">
                                        {(error) && error['inventory_id']}
                                    </span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="notes">Notes</label>
                                    <textarea
                                        name='notes'
                                        type="text"
                                        className="form-control"
                                        id="notes"
                                        placeholder="Add notes..."
                                        value={stockTrasferValues['notes']}
                                        onChange={(e) => {
                                            setStockTrasferValues({ ...stockTrasferValues, [e.target.name]: e.target.value })
                                        }}
                                    />
                                    <span id="notes-error" className="error text-danger">
                                        {(error) && error['notes']}
                                    </span>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button onClick={handleSubmitForTransfer} type="button" className="btn btn-primary">Submit</button>
                                {notificationMessage}
                            </div>
                        </div>
                    </div> */}
                {/* {console.log(stockTrasferValues)} */}
            </Popup>

        </ >
    );
};
export default ShowInventoryDetail
