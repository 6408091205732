import React, { useEffect, useState } from 'react';
import { AxiosGet, ContentHeader, assetUrl } from '../../Components/Components';
import ImagePlaceholder from '../../Assets/Images/image_placeholder.jpg';
import { CustomTable, SelectColumnFilter } from '../../Components/CustomTable/CustomReactTable';
import { Link } from 'react-router-dom';
const Assets = () => {

    // React-table start
    const [data, setData] = useState([]);

    function assetStatus(row) {
        if (row.status === 1) {
            return 'Operational'
        } else {
            return 'Unoperational'
        }
    }

    const columns = React.useMemo(
        () => [
            {
                accessor: 'name',
                Header: 'Asset Name',
                Cell: ({ cell }) => (
                    <Link className='mx-2' to={`/asset/view/${cell.row.values.id}`}>
                        {cell.value}
                    </Link>)
            },
            {
                accessor: 'image_path',
                Header: 'Image',
                disableFilters: true,
                Cell: ({ cell }) => (
                    (cell.row.values && cell.row.values.image_path != '' && cell.row.values.image_path != null) ?
                        <img className='img-fluid' src={assetUrl + cell.row.values.image_path} height={40} width={40} />
                        :
                        <img className='img-fluid' src={ImagePlaceholder} height={40} width={40} />
                )
            },
            {
                accessor: 'location.name',
                Header: 'Location',
                Filter: SelectColumnFilter,
            },
            {
                accessor: 'category.name',
                Header: 'Category',
                Filter: SelectColumnFilter,
            },
            {
                accessor: 'model',
                Header: 'Model',
            },
            {
                accessor: 'barcode',
                Header: 'Barcode',
            },
            {
                accessor: assetStatus,
                Header: 'Status',
                // disableFilters: true,
                Filter: SelectColumnFilter,
                Cell: ({ cell }) => (
                    (cell.value == 'Operational') ?
                        <span className={'badge bg-success'}>Operational</span> :
                        <span className={'badge bg-secondary'}>Unoperational</span>
                )
            },
            {
                accessor: 'id',
                Header: 'Action',
                disableFilters: true,
                Cell: ({ cell }) => (
                    <>
                        <Link className='mx-2' to={`/asset/edit/${cell.row.values.id}`}>
                            <i className="far fa-edit text-primary"></i>
                        </Link>
                        {/* <Link className='' to={`/asset/delete/${cell.row.values.id}`}>
                            <i className="fas fa-trash-alt text-danger"></i>
                        </Link> */}
                    </>
                )
            },
        ],
        []
    )

    useEffect(() => {
        AxiosGet('assets').then(result => {
            setData(result.data.data);
            // console.log(result.data.data)
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, []);
    // React-table end

    return (
        <>
            <ContentHeader
                h1='Assets'
                buttons={
                    [
                        { url: 'asset/add', label: 'Add Asset' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'assets', label: 'Assets', active: true },
                    ]
                }
            />
            <div className="row">
                <div className="col-12">
                    <CustomTable
                        columns={columns}
                        data={data}
                        height='auto'
                    />
                </div>
            </div>
        </>
    );
};
export default Assets
