// import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AxiosGet, assetUrl, ComponentLabelValueRow, ContentHeader } from '../../Components/Components'
// import AgTable, { Action, Show } from '../../Components/CustomTable/AgTable';
import PdfPlaceholder from '../../Assets/Images/pdf_placeholder.png';
import { isEmpty } from 'lodash';
import { CustomTable, SelectColumnFilter } from '../../Components/CustomTable/CustomReactTable';


const ShowAsset = () => {

    const params = useParams();
    const [loading, setLoading] = useState(true)
    // const [values, setValues] = useState({
    //     name: '',
    //     description: '',
    //     image_path: '',
    //     location_id: '',
    //     category_id: '',
    //     model: '',
    //     barcode: '',
    //     purchase_price: '',
    //     purchase_date: '',
    //     residual_value: '',
    //     useful_life: '',
    //     purchase_receipt_path: '',
    //     assignee_id: '',
    //     additional_assignee_id: '',
    //     team_id: '',
    //     vendor_id: '',
    //     customer_id: '',
    //     placed_in_service_date: '',
    //     warranty_expiry: '',
    //     notes: '',
    //     warranty_file_path: '',
    //     parts_id: '',
    //     has_parent: '',
    //     parent_id: '',
    //     status: '',
    //     vendor: '',
    //     category: '',
    //     location: '',
    //     worker: '',
    //     additional_worker: '',
    //     team: '',
    // });
    const [values, setValues] = useState([]);
    const [workorders, setWorkorders] = useState([]);
    const [assetParts, setAssetParts] = useState([]);

    const [documentList, setDocumentList] = useState('');

    useEffect(() => {
        (async () => {
            const result = await AxiosGet('asset/' + params.id);
            console.log(result);
            setValues(result.data.data);
            setAssetParts(result.data.asset_parts);
            setWorkorders(result.data.workorder);
            // setValues({
            //     name: result.data.data.name,
            //     description: result.data.data.description,
            //     location_id: result.data.data.location_id,
            //     category_id: result.data.data.category_id,
            //     model: result.data.data.model,
            //     barcode: result.data.data.barcode,
            //     purchase_price: result.data.data.purchase_price,
            //     purchase_date: result.data.data.purchase_date,
            //     residual_value: result.data.data.residual_value,
            //     useful_life: result.data.data.useful_life,
            //     assignee_id: result.data.data.assignee_id,
            //     additional_assignee_id: result.data.data.additional_assignee_id,
            //     team_id: result.data.data.team_id,
            //     vendor_id: result.data.data.vendor_id,
            //     customer_id: result.data.data.customer_id,
            //     placed_in_service_date: result.data.data.placed_in_service_date,
            //     warranty_expiry: result.data.data.warranty_expiry,
            //     notes: result.data.data.notes,
            //     parts_id: result.data.data.parts_id,
            //     has_parent: result.data.data.has_parent,
            //     parent_id: result.data.data.parent_id,
            //     status: result.data.data.status,
            //     image_path: result.data.data.image_path,
            //     purchase_receipt_path: result.data.data.purchase_receipt_path,
            //     warranty_file_path: result.data.data.warranty_file_path,
            //     vendor: result.data.vendor,
            //     location: result.data.location,
            //     category: result.data.category,
            //     worker: result.data.worker,
            //     additional_worker: result.data.additional_worker,
            //     team: result.data.team,
            // });
            setLoading(false);
        })();
    }, [params.id]);


    useEffect(() => {
        AxiosGet('documents/asset/' + params.id).then(result => {
            // console.log(result);
            setDocumentList(result.data.data)
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, [params.id]);

    //React-table start
    function workorderTimeConsumed(row) {
        if (row.WorkOrderAssignee && !isEmpty(row.WorkOrderAssignee)) {
            let time_consumed = 0;
            row.WorkOrderAssignee.map((item, i) => {
                time_consumed = time_consumed + item.time_consumed
            })
            // WorkOrderAssignee = WorkOrderAssignee.substring(0, WorkOrderAssignee.length - 2);
            return parseFloat(time_consumed).toFixed(2);
        }

    }
    function workorderAssignee(row) {
        let WorkOrderAssignee = '';
        if (row.WorkOrderAssignee && !isEmpty(row.WorkOrderAssignee)) {
            row.WorkOrderAssignee.map((item, i) => {
                WorkOrderAssignee += item.people_detail.name + ', '
            })
            WorkOrderAssignee = WorkOrderAssignee.substring(0, WorkOrderAssignee.length - 2);
            return WorkOrderAssignee;
        }
    }

    function workorderStatus(row) {
        let status = 'Open';
        if (row.status === 2) {
            status = 'In progress';
        } else if (row.status === 3) {
            status = 'On hold';
        } else if (row.status === 4) {
            status = 'Complete';
        }
        return status
    }

    function assetPartPmEnabled(row) {
        let pmEnabled = 'No';
        if (row.pm_enabled === 1) {
            pmEnabled = 'Yes';
        }
        return pmEnabled
    }

    const columns = React.useMemo(
        () => [
            {
                accessor: 'id',
                Header: 'Id',
            },
            {
                accessor: 'title',
                Header: 'Title',
                Cell: ({ cell }) => (
                    <Link className='mx-2' to={`/work-order/view/${cell.row.values.id}`}>
                        {cell.value}
                    </Link>
                )
            },
            {
                accessor: 'description',
                Header: 'Description',
            },
            // {
            //     accessor: 'asset_id',
            //     Header: 'Asset',
            // },
            {
                accessor: 'category_id.name',
                Header: 'Category',
                Filter: SelectColumnFilter,
            },
            {
                accessor: 'priority',
                Header: 'Priority',
            },
            {
                accessor: workorderAssignee,
                Header: 'Assignee',
                Cell: ({ cell }) => (
                    (cell.row.original.WorkOrderAssignee && cell.row.original.WorkOrderAssignee !== '' && cell.row.original.WorkOrderAssignee) &&
                    cell.row.original.WorkOrderAssignee.map((item, i) => {
                        return <span key={i}>
                            <Link to={`/people/view/${item.people_id}`}>
                                {item.people_detail.name}
                            </Link>
                            {(i < cell.row.original.WorkOrderAssignee.length - 1) && ', '}
                        </span>
                    })
                )
            },
            {
                accessor: workorderTimeConsumed,
                Header: 'Time Consumed',
            },
            // {
            //     accessor: 'due_date',
            //     Header: 'Due Date',
            // },
            {
                accessor: workorderStatus,
                Header: 'Status',
                Filter: SelectColumnFilter,
                Cell: ({ cell }) => {
                    let labelClass = '';
                    if (cell.value === 'Open') {
                        labelClass = 'bg-success';
                    } else if (cell.value === 'On hold') {
                        labelClass = 'bg-warning';
                    } else if (cell.value === 'In progress') {
                        labelClass = 'bg-primary';
                    } else if (cell.value === 'Complete') {
                        labelClass = 'bg-secondary text-white';
                    }
                    return <span className={'badge ' + labelClass}>{cell.value}</span>
                }
            },
            {
                Header: 'Action',
                disableFilters: true,
                Cell: ({ cell }) => (
                    <>
                        <Link className='mx-2' to={`/work-order/edit/${cell.row.original.id}`}>
                            <i className="far fa-edit text-primary"></i>
                        </Link>
                        <Link className='' to={`/work-order/delete/${cell.row.values.id}`}>
                            <i className="fas fa-trash-alt text-danger"></i>
                        </Link>
                    </>
                )
            },
        ],
        []
    )
    const columnsAssetParts = React.useMemo(
        () => [
            {
                accessor: 'part.name',
                Header: 'Name',
                disableFilters: true,
            },
            // {
            //     accessor: 'title',
            //     Header: 'Title',
            //     Cell: ({ cell }) => (
            //         <Link className='mx-2' to={`/work-order/view/${cell.row.values.id}`}>
            //             {cell.value}
            //         </Link>
            //     )
            // },
            {
                accessor: 'quantity',
                Header: 'Quantity',
                disableFilters: true,
            },
            {
                accessor: 'replace_after',
                Header: 'Replace After',
                disableFilters: true,
                Cell: ({ cell }) => (
                    <>
                        {cell.value} months
                    </>
                )
            },
            // {
            //     accessor: 'category_id.name',
            //     Header: 'Category',
            //     Filter: SelectColumnFilter,
            // },
            // {
            //     accessor: 'priority',
            //     Header: 'Priority',
            // },
            // {
            //     accessor: workorderAssignee,
            //     Header: 'Assignee',
            //     Cell: ({ cell }) => (
            //         (cell.row.original.WorkOrderAssignee && cell.row.original.WorkOrderAssignee !== '' && cell.row.original.WorkOrderAssignee) &&
            //         cell.row.original.WorkOrderAssignee.map((item, i) => {
            //             return <span key={i}>
            //                 <Link to={`/people/view/${item.people_id}`}>
            //                     {item.people_detail.name}
            //                 </Link>
            //                 {(i < cell.row.original.WorkOrderAssignee.length - 1) && ', '}
            //             </span>
            //         })
            //     )
            // },
            // {
            //     accessor: workorderTimeConsumed,
            //     Header: 'Time Consumed',
            // },
            // {
            //     accessor: 'due_date',
            //     Header: 'Due Date',
            // },
            {
                accessor: assetPartPmEnabled,
                Header: 'PM Enabled',
                disableFilters: true,
                Cell: ({ cell }) => {
                    let labelClass = '';
                    if (cell.value === 'No') {
                        labelClass = 'bg-secondary text-white';
                    } else if (cell.value === 'Yes') {
                        labelClass = 'bg-primary';
                    }
                    return <span className={'badge ' + labelClass}>{cell.value}</span>
                }
            },
            // {
            //     Header: 'Action',
            //     disableFilters: true,
            //     Cell: ({ cell }) => (
            //         <>
            //             <Link className='mx-2' to={`/work-order/edit/${cell.row.original.id}`}>
            //                 <i className="far fa-edit text-primary"></i>
            //             </Link>
            //             <Link className='' to={`/work-order/delete/${cell.row.values.id}`}>
            //                 <i className="fas fa-trash-alt text-danger"></i>
            //             </Link>
            //         </>
            //     )
            // },
        ],
        []
    )

    //React-table end

    return (
        <>

            <ContentHeader
                h1='Asset Details'
                buttons={
                    [
                        { url: 'asset/edit/' + params.id, label: 'Edit Asset' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'Asset', label: 'Asset Details', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm mb-4'>
                <div className="row">
                    <div className="col-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                                <button className="nav-link active" id="asset-details-tab" data-bs-toggle="tab" data-bs-target="#asset-details" type="button" role="tab" aria-controls="asset-details" aria-selected="true">Asset Details</button>
                            </li>
                            <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                                <button className="nav-link" id="asset-images-tab" data-bs-toggle="tab" data-bs-target="#asset-images" type="button" role="tab" aria-controls="asset-images" aria-selected="false">Asset Images</button>
                            </li>
                            <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                                <button className="nav-link" id="work-orders-tab" data-bs-toggle="tab" data-bs-target="#work-orders" type="button" role="tab" aria-controls="work-orders" aria-selected="false">Work Orders</button>
                            </li>
                            <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                                <button className="nav-link" id="asset-parts-tab" data-bs-toggle="tab" data-bs-target="#asset-parts" type="button" role="tab" aria-controls="asset-parts" aria-selected="false">Parts</button>
                            </li>
                        </ul>
                        <div className="tab-content py-3 px-3" id="myTabContent">
                            <div className="tab-pane fade show active" id="asset-details" role="tabpanel" aria-labelledby="asset-details-tab">
                                {
                                    (!loading && values !== '') ?
                                        <>
                                            <div className='row pt-2'>
                                                <div className='col-12'>
                                                    <h6 className=''>Asset Information</h6>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-6'>
                                                    <ComponentLabelValueRow
                                                        label='Name'
                                                        value={values.name}
                                                    />
                                                    <ComponentLabelValueRow
                                                        label='Model'
                                                        value={values.model}
                                                    />
                                                    <ComponentLabelValueRow
                                                        label='Barcode'
                                                        value={values.barcode}
                                                    />
                                                    <ComponentLabelValueRow
                                                        label='Description'
                                                        value={values.description}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <ComponentLabelValueRow
                                                        label='Status'
                                                        value={
                                                            (values.status) && (values.status === 1) ? <span className="badge bg-success">operational</span> : <span className="badge bg-danger">Inoperational</span>
                                                        }
                                                    />
                                                    <ComponentLabelValueRow
                                                        label='Category'
                                                        value={values.category ? values.category.label : 'N/A'}
                                                    />
                                                    <ComponentLabelValueRow
                                                        label='Vendor'
                                                        value={values.vendor ? values.vendor.label : 'N/A'}
                                                    />
                                                    <ComponentLabelValueRow
                                                        label='Location'
                                                        value={values.location ? values.location.label : 'N/A'}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row pt-4'>
                                                <div className='col-12'>
                                                    <h6 className=''>Depreciation/Devaluation</h6>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-6'>
                                                    <ComponentLabelValueRow
                                                        label='Purchase Price'
                                                        value={values.purchase_price}
                                                    />
                                                    <ComponentLabelValueRow
                                                        label='Residual Value'
                                                        value={values.residual_value}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <ComponentLabelValueRow
                                                        label='Purchase Date'
                                                        value={values.purchase_date}
                                                    />
                                                    <ComponentLabelValueRow
                                                        label='Useful Life (in years)'
                                                        value={values.useful_life}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row pt-4'>
                                                <div className='col-12'>
                                                    <h6 className=''>Assigned to</h6>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-6'>
                                                    <ComponentLabelValueRow
                                                        label='Primary Worker'
                                                        value={values.worker ? values.worker.label : 'N/A'}
                                                    />
                                                    <ComponentLabelValueRow
                                                        label='Additional Worker(s)'
                                                        value={values.additional_worker ? values.additional_worker : 'N/A'}
                                                        valueIsArray={true}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <ComponentLabelValueRow
                                                        label='Team(s)'
                                                        value={values.team ? values.team : 'N/A'}
                                                        valueIsArray={true}
                                                    />
                                                </div>
                                            </div>

                                            <div className='row pt-4'>
                                                <div className='col-12'>
                                                    <h6 className=''>Warranty</h6>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-sm-6'>
                                                    <ComponentLabelValueRow
                                                        label='Warranty Start Date'
                                                        value={values.placed_in_service_date}
                                                    />
                                                    <ComponentLabelValueRow
                                                        label='Notes'
                                                        value={values.notes}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <ComponentLabelValueRow
                                                        label='Warranty Expiry Date'
                                                        value={values.warranty_expiry}
                                                    />
                                                </div>
                                            </div>
                                        </> : 'loading...'
                                }
                            </div>
                            <div className="tab-pane fade" id="asset-images" role="tabpanel" aria-labelledby="asset-images-tab">
                                <div className='row'>
                                    {
                                        (!loading && documentList && documentList !== '') ?
                                            documentList.map((item, i) => (
                                                <div key={i} className='col-auto d-flex flex-column my-2'>
                                                    <div className="card shadow-sm" style={{ width: '14rem' }}>
                                                        <img
                                                            src={item.document_path.split('.').reverse()[0] === 'pdf' ? PdfPlaceholder : assetUrl + item.document_path}
                                                            style={{ height: '150px', objectFit: 'cover' }}
                                                            className="card-img-top"
                                                            alt={item.name} />
                                                        <div className="card-body py-2">
                                                            <h6 className="card-text">{item.name}</h6>
                                                            <p className="card-text" style={{ fontSize: '0.85rem' }}>
                                                                {
                                                                    item.description !== '' && item.description !== null ?
                                                                        item.description : 'No description available!'
                                                                }
                                                            </p>
                                                            <div className='d-flex justify-content-between'>
                                                                {/* <span
                                                                    onClick={() => { removeDocument(item.id) }}
                                                                    style={{ cursor: 'pointer' }}
                                                                    className="card-link">
                                                                    <i className='fas fa-trash-alt text-danger'></i>
                                                                </span> */}
                                                                <a href={assetUrl + item.document_path}
                                                                    target='_blank'
                                                                    rel="noreferrer"
                                                                    className="card-link text-decoration-none">
                                                                    View &nbsp;
                                                                    <i className="fas fa-external-link"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <span>
                                                No documents added yet...
                                            </span>
                                    }
                                </div>
                            </div>
                            <div className="tab-pane fade" id="work-orders" role="tabpanel" aria-labelledby="work-orders-tab">
                                <div className='row'>
                                    <div className='col-12'>
                                        <CustomTable
                                            columns={columns}
                                            data={workorders}
                                            height='auto'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="asset-parts" role="tabpanel" aria-labelledby="asset-parts-tab">
                                <div className='row'>
                                    <div className='col-12'>
                                        <CustomTable
                                            columns={columnsAssetParts}
                                            data={assetParts}
                                            height='auto'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ShowAsset
