import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { apiUrl, assetUrl, AxiosGet, AxiosPost, ComponentInput, ComponentSelect, ContentHeader, handleSubmitForUpdate, onChange } from '../../Components/Components'
import * as qs from 'qs';
import PdfPlaceholder from '../../Assets/Images/pdf_placeholder.png';

const EditPart = () => {
    const params = useParams()
    const [dropdown, setDropdown] = useState('');
    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(true);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [refetchWorkOrderDocuments, setRefetchWorkOrderDocuments] = useState(0);

    const [values, setValues] = useState({
        name: "",
        description: "",
        barcode: "",
        category_id: "",
        status: "",
    });

    const [documentList, setDocumentList] = useState('');
    const [document, setDocument] = useState({
        name: '',
        description: '',
        document_path: '',
    });

    const formdata = new FormData();
    function addImageInWorkOrder() {
        formdata.append("name", document.name);
        formdata.append("description", document.description);
        formdata.append("document_path", document.document_path);
        formdata.append("type", 2);
        formdata.append("relation_key", params.id);
        formdata.append("tag", 2);
        if (true) {
            AxiosPost('document/add',
                formdata
            ).then(result => {
                setDocument({
                    name: '',
                    document_path: ''
                })
                setError('')
                setRefetchWorkOrderDocuments(new Date().getTime())
            }).catch(err => {
                setError(err.response.data.errors)
                console.log(err.response, '----')
            })
        }
    }


    function removeDocumentFromWorkOrder(id) {
        if (true) {
            var data = qs.stringify({
                'id': id
            });
            var config = {
                method: 'delete',
                url: apiUrl + 'document/remove',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };
            axios(config).then(result => {
                setRefetchWorkOrderDocuments(new Date().getTime())
            }).catch(err => {
                console.log(err.response, '----')
            })
        }
    }


    useEffect(() => {
        (async () => {
            const categories = await AxiosGet('dropdown/categories/3');
            setDropdown({ categories });
            const fetchedData = await AxiosGet('part/' + params.id);
            setValues({
                name: fetchedData.data.data.name,
                description: fetchedData.data.data.description,
                barcode: fetchedData.data.data.barcode,
                category_id: fetchedData.data.data.category_id,
                status: fetchedData.data.data.status,
            });
            // console.log(fetchedData)
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        AxiosGet('documents/part/' + params.id).then(result => {
            setDocumentList(result.data.data)
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, [refetchWorkOrderDocuments]);

    return (
        <>
            <ContentHeader
                h1='Edit Part'
                buttons={
                    [
                        { url: 'Parts', label: 'All Parts' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'parts', label: 'Parts', active: false },
                        { url: 'part/edit', label: 'Edit', active: true },
                    ]
                }
            />

            <div className='border rounded-2 shadow-sm'>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                        <button className="nav-link active" id="basic-details-tab" data-bs-toggle="tab" data-bs-target="#basic-details" type="button" role="tab" aria-controls="basic-details" aria-selected="true">Part Details</button>
                    </li>
                    <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                        <button className="nav-link" id="workorder-images-tab" data-bs-toggle="tab" data-bs-target="#workorder-images" type="button" role="tab" aria-controls="workorder-images" aria-selected="false">Images</button>
                    </li>
                </ul>
                <div className="tab-content pt-3 pb-2 px-3" id="myTabContent">
                    <div className="tab-pane fade show active" id="basic-details" role="tabpanel" aria-labelledby="basic-details-tab">
                            <div className="row">
                                <div className="col-12">
                                    <div className="mb-3">
                                        <ComponentInput
                                            name='name'
                                            type='text'
                                            displayName='Part Name'
                                            className='form-control'
                                            value={values['name']}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            error={error}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <ComponentInput
                                            name='description'
                                            type='text'
                                            displayName='Description'
                                            className='form-control'
                                            value={values['description']}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            error={error}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <ComponentInput
                                            name='barcode'
                                            type='text'
                                            displayName='Barcode'
                                            className='form-control'
                                            value={values['barcode']}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            error={error}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        {dropdown.categories &&
                                            <ComponentSelect
                                                name='category_id'
                                                displayName='Category'
                                                className='form-select'
                                                options={dropdown.categories.data}
                                                onChange={(e) => onChange(e, values, setValues)}
                                                selected={values['category_id']}
                                                label='label'
                                                value='value'
                                                error={error}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button
                                        onClick={
                                            (e) => {
                                                handleSubmitForUpdate(
                                                    e,
                                                    'part/edit',
                                                    {
                                                        id: params.id,
                                                        name: values.name,
                                                        description: values.description,
                                                        barcode: values.barcode,
                                                        category_id: values.category_id,
                                                        status: values.status,
                                                    },
                                                    setError,
                                                    setNotificationMessage
                                                )
                                            }
                                        }
                                        className="btn btn-primary">
                                        Submit
                                    </button>
                                    {notificationMessage}
                                </div>
                            </div>

                    </div>
                    <div className="tab-pane fade" id="workorder-images" role="tabpanel" aria-labelledby="workorder-images-tab">
                        <div className='row'>
                            <div className='col-12'>
                                <h5>Document / Images</h5>
                                <div className="row gx-3 gy-2 align-items-end  pb-4 mt-2">
                                    <div className="col-auto">
                                        <ComponentInput
                                            name='name'
                                            type='text'
                                            displayName='Title'
                                            className='form-control'
                                            value={document['name']}
                                            onChange={(e) => onChange(e, document, setDocument)}
                                            error={error}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <ComponentInput
                                            name='description'
                                            type='text'
                                            displayName='Description'
                                            className='form-control'
                                            value={document['description']}
                                            onChange={(e) => onChange(e, document, setDocument)}
                                            error={error}
                                        />
                                    </div>
                                    <div className="col-auto">
                                        <label className="form-label fw-semibold" htmlFor="document_path">Document</label>
                                        <input
                                            type="file"
                                            onChange={
                                                (e) => setDocument({ ...document, [e.target.name]: e.target.files[0] })
                                            }
                                            name="document_path"
                                            className="form-control"
                                            id="document_path" />
                                        <span id='document_path-error' className='error text-danger'>
                                            {error && error['document_path']}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            className='btn btn-primary'
                                            onClick={
                                                () => addImageInWorkOrder()
                                            }>
                                            Add Image
                                        </button>
                                    </div>
                                </div>
                                <div className='row border-top mt-3 py-4 d-flex justify-content-md-start justify-content-center'>
                                    {
                                        (!loading && documentList && documentList != '') ?
                                            documentList.map((item, i) => (
                                                <div key={i} className='col-auto d-flex flex-column my-2'>
                                                    <div className="card shadow-sm" style={{ width: '14rem' }}>
                                                        <img
                                                            src={item.document_path.split('.').reverse()[0] == 'pdf' ? PdfPlaceholder : assetUrl + item.document_path}
                                                            style={{ height: '150px', objectFit: 'cover' }}
                                                            className="card-img-top"
                                                            alt={item.name} />
                                                        <div className="card-body py-2">
                                                            <h6 className="card-text">{item.name}</h6>
                                                            <p className="card-text" style={{ fontSize: '0.85rem' }}>
                                                                {
                                                                    item.description != '' && item.description != null ?
                                                                        item.description : 'No description available!'
                                                                }
                                                            </p>
                                                            <div className='d-flex justify-content-between'>
                                                                <span
                                                                    onClick={() => { removeDocumentFromWorkOrder(item.id) }}
                                                                    style={{ cursor: 'pointer' }}
                                                                    className="card-link">
                                                                    <i className='fas fa-trash-alt text-danger'></i>
                                                                </span>
                                                                <a href={assetUrl + item.document_path}
                                                                    target='_blank'
                                                                    className="card-link text-decoration-none">
                                                                    View &nbsp;
                                                                    <i className="fas fa-external-link"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <span>
                                                No documents added yet...
                                            </span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default EditPart