import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { ContentHeader, ComponentInput, ComponentSelect, AxiosGet, handleSubmitForUpdate, onChange, ComponentTextarea, AxiosPost, apiUrl } from '../../Components/Components'
import * as qs from 'qs';


const EditChecklist = () => {

    const params = useParams()
    const [error, setError] = useState([]);
    const [refetch, setRefetch] = useState(0);
    const [loading, setLoading] = useState(true);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [checklistItems, setChecklistItems] = useState([]);

    const [values, setValues] = useState({
        name: "",
    });
    const [checklistValues, setChecklistValues] = useState({
        description: "",
        type: "",
        options: "",
    });

    useEffect(() => {
        AxiosGet('checklist/' + params.id).then(result => {
            setValues({
                name: result.data.data.name,
            });
            setChecklistItems(result.data.data.checklist_item);
            setLoading(false)
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, [refetch]);


    function addChecklistItem(e) {
        let option = checklistValues.options
        if (checklistValues.type == 1) {
            option = 'pass,fail,flag'
        } else if (checklistValues.type == 5) {
            option = 'open,inprogress,complete'
        }
        AxiosPost('checklist-item/add',
            {
                'description': checklistValues.description,
                'checklist_id': params.id,
                'type': checklistValues.type,
                'options': option
            },
        ).then(result => {
            setRefetch(new Date().getTime())
            setChecklistValues({
                'description': '',
                'type': '',
                'options': '',
            })
            setError('')
        }).catch(err => {
            setError(err.response.data.errors)
            console.log(err.response, '----')
        })
    }

    function removeItemsFromChecklist(id) {
        if (true) {
            var data = qs.stringify({
                'id': id
            });
            var config = {
                method: 'delete',
                url: apiUrl + 'checklist-item/remove',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };
            axios(config).then(result => {
                setRefetch(new Date().getTime())
            }).catch(err => {
                console.log(err.response, '----')
            })
        }
    }



    return (
        <>
            <ContentHeader
                h1='Edit Checklist'
                buttons={
                    [
                        { url: 'checklists', label: 'All Checklist' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'checklists', label: 'Checklist', active: false },
                        { url: 'checklist/edit', label: 'Edit', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm px-4 py-4'>
                <span>
                    <div className="row gy-2 gx-3 d-flex justify-content-start align-items-center">
                        <div className="col-9">
                            <div className="mb-3">
                                <ComponentInput
                                    name='name'
                                    type='text'
                                    displayName='Checklist Name'
                                    className='form-control'
                                    value={values['name']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                        </div>
                        <div className='col-auto'>
                            <button onClick={
                                (e) => {
                                    handleSubmitForUpdate(
                                        e,
                                        'checklist/edit',
                                        {
                                            id: params.id,
                                            name: values.name,
                                        },
                                        setError,
                                        setNotificationMessage
                                    )
                                }
                            } className="btn btn-primary mt-3">Update Name</button>
                            {notificationMessage}

                        </div>
                    </div>
                </span>
            </div>
            <h5 className='mt-4'>Checklist Items</h5>
            <div className='border rounded-2 shadow-sm p-3 mt-2'>

                <div className="row mt-3 py-2">
                    <div className='col-sm-6 pe-3'>
                        <span>
                            <div className="col-12">
                                <div className="mb-3">
                                    <ComponentInput
                                        name='description'
                                        type='text'
                                        displayName='Description'
                                        className='form-control'
                                        value={checklistValues['description']}
                                        onChange={(e) => onChange(e, checklistValues, setChecklistValues)}
                                        error={error}
                                    />
                                </div>
                                <div className="mb-3">
                                    <ComponentSelect
                                        name='type'
                                        displayName='Type'
                                        className='form-select'
                                        options={[
                                            { value: 1, label: 'Inspection' },
                                            { value: 2, label: 'Number' },
                                            { value: 3, label: 'Text' },
                                            { value: 4, label: 'Boolean (Yes/No)' },
                                            { value: 5, label: 'Status' },
                                        ]}
                                        onChange={(e) => onChange(e, checklistValues, setChecklistValues)}
                                        selected={checklistValues['type']}
                                        label='label'
                                        value='value'
                                        error={error}
                                    />
                                </div>
                                <div className="mb-3">
                                    <ComponentTextarea
                                        name='options'
                                        displayName='Options'
                                        className='form-control'
                                        value={checklistValues['options']}
                                        onChange={(e) => onChange(e, checklistValues, setChecklistValues)}
                                        error={error}
                                        // disabled={true}
                                        disabled={checklistValues['type'] != 4 ? true : false}
                                    />
                                </div>
                            </div>
                            <div className='col-12 pt-3'>
                                <button onClick={
                                    (e) => {
                                        addChecklistItem(e)
                                    }
                                } className="btn btn-primary">Add Checklist Item</button>
                                {notificationMessage}
                            </div>
                        </span>
                    </div>
                    <div className='col-sm-6'>
                        <div className='table-responsive'>
                            <table className='table table-stripped table-hover'>
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Type</th>
                                        <th>Options</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !loading && checklistItems && checklistItems != '' ?
                                            checklistItems.map((item, i) => {
                                                let type = '';
                                                if (item.type == 1) {
                                                    type = 'Inspection'
                                                } else if (item.type == 2) {
                                                    type = 'Number'
                                                } else if (item.type == 3) {
                                                    type = 'Text'
                                                } else if (item.type == 4) {
                                                    type = 'Boolean (Yes/No)'
                                                } else {
                                                    type = 'Status'
                                                }
                                                let options = '';
                                                if (item.type == 1) {
                                                    options = 'Pass, Fail or Flag'
                                                } else if (item.type == 2) {
                                                    options = 'Number (123456789)'
                                                } else if (item.type == 3) {
                                                    options = 'Text (abc)'
                                                } else if (item.type == 4) {
                                                    options = 'Yes/No'
                                                } else {
                                                    options = 'Open, In Progress, On Hold or Complete'
                                                }
                                                if (true) {
                                                    return <tr key={i}>
                                                        <td>{item.description}</td>
                                                        <td width={150}>
                                                            {type}
                                                        </td>
                                                        <td width={200}>
                                                            {options}
                                                        </td>
                                                        <td width={30}>
                                                            <span
                                                                onClick={() => { removeItemsFromChecklist(item.id) }} 
                                                                style={{ cursor: 'pointer' }}>
                                                                <i className='fas fa-trash-alt text-danger'></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                }
                                            }) :
                                            <tr>
                                                <td colSpan={3}>No items added yet...</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditChecklist