import React, { useEffect, useState } from 'react';
import { AxiosGet, ContentHeader } from '../../Components/Components';
import { Link, useParams } from 'react-router-dom';
import { CustomTable, SelectColumnFilter } from '../../Components/CustomTable/CustomReactTable';
import { isEmpty } from 'lodash';
import { cookie } from '../../services/CookieService';

const WorkOrders = () => {

    const params = useParams();

    // React-table start
    const [data, setData] = useState([]);
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [refetchColumns, setRefetchColumns] = useState(0);

    function workorderTimeConsumed(row) {
        if (row.WorkOrderAssignee && !isEmpty(row.WorkOrderAssignee)) {
            let time_consumed = 0;
            row.WorkOrderAssignee.map((item, i) => {
                time_consumed = time_consumed + item.time_consumed
            })
            // WorkOrderAssignee = WorkOrderAssignee.substring(0, WorkOrderAssignee.length - 2);
            return parseFloat(time_consumed).toFixed(2);
        }

    }

    function workorderAssignee(row) {
        let WorkOrderAssignee = '';
        if (row.WorkOrderAssignee && !isEmpty(row.WorkOrderAssignee)) {
            row.WorkOrderAssignee.map((item, i) => {
                WorkOrderAssignee += item.people_detail.name + ', '
            })
            WorkOrderAssignee = WorkOrderAssignee.substring(0, WorkOrderAssignee.length - 2);
            return WorkOrderAssignee;
        }
    }

    function workorderStatus(row) {
        let status = 'Open';
        if (row.status === 2) {
            status = 'In progress';
        } else if (row.status === 3) {
            status = 'On hold';
        } else if (row.status === 4) {
            status = 'Complete';
        }
        return status
    }

    const columns = React.useMemo(
        () => [
            {
                accessor: 'id',
                Header: 'Id',
            },
            {
                accessor: 'title',
                Header: 'Title',
                Cell: ({ cell }) => (
                    <Link className='mx-2' to={`/work-order/edit/${cell.row.values.id}`}>
                        {cell.value}
                    </Link>
                )
            },
            {
                accessor: 'description',
                Header: 'Description',
            },
            {
                accessor: 'asset_id',
                Header: 'Asset',
            },
            {
                accessor: 'category_id.name',
                Header: 'Category',
                Filter: SelectColumnFilter,
            },
            {
                accessor: 'priority',
                Header: 'Priority',
            },
            {
                id: 'workorderAssignee',
                accessor: workorderAssignee,
                Header: 'Assignee',
                Cell: ({ cell }) => (
                    (cell.row.original.WorkOrderAssignee && cell.row.original.WorkOrderAssignee !== '' && cell.row.original.WorkOrderAssignee) &&
                    cell.row.original.WorkOrderAssignee.map((item, i) => {
                        return <span key={i}>
                            <Link to={`/people/view/${item.people_id}`}>
                                {item.people_detail.name}
                            </Link>
                            {(i < cell.row.original.WorkOrderAssignee.length - 1) && ', '}
                        </span>
                    })
                )
            },
            {
                id: 'workorderTimeConsumed',
                accessor: workorderTimeConsumed,
                Header: 'Time Consumed',
            },
            {
                accessor: 'due_date',
                Header: 'Due Date',
            },
            {
                id: 'workorderStatus',
                accessor: workorderStatus,
                Header: 'Status',
                Filter: SelectColumnFilter,
                Cell: ({ cell }) => {
                    let labelClass = '';
                    if (cell.value === 'Open') {
                        labelClass = 'bg-success';
                    } else if (cell.value === 'On hold') {
                        labelClass = 'bg-warning';
                    } else if (cell.value === 'In progress') {
                        labelClass = 'bg-primary';
                    } else if (cell.value === 'Complete') {
                        labelClass = 'bg-secondary text-white';
                    }
                    return <span className={'badge ' + labelClass}>{cell.value}</span>
                }
            },
            {
                Header: 'Action',
                disableFilters: true,
                Cell: ({ cell }) => (
                    <>
                        <Link className='mx-2' to={`/work-order/edit/${cell.row.original.id}`}>
                            <i className="far fa-edit text-primary"></i>
                        </Link>
                        <Link className='' to={`/work-order/delete/${cell.row.values.id}`}>
                            <i className="fas fa-trash-alt text-danger"></i>
                        </Link>
                    </>
                )
            },
        ],
        []
    )

    useEffect(() => {
        // cookie based column visibility start
        let cookies = cookie.getAll();
        if (cookies && !isEmpty(cookies) && cookies !== null) {
            let hiddenColumnNames = [];
            Object.keys(cookies).map((item, i) => {
                if (item.includes(window.location.hash + '-column|') && (cookies[item] === 'true')) {
                    hiddenColumnNames.push(item.split("|")[1]);
                    // console.log(item);
                    // console.log(cookies[item]);
                }
            });
            setHiddenColumns(hiddenColumnNames);
        }
        // cookie based column visibility end

        let work_order_filter = [];
        if (params.id === 'all') {
            work_order_filter = '/all';
        }

        AxiosGet('work-orders' + work_order_filter).then(result => {
            setData(result.data.data);
            // console.log(result.data.data)
        }).catch(err => {
            console.log(err.response, '----')
        })

    }, [params.id, refetchColumns]);

    // React Table End

    return (
        <>
            <ContentHeader
                h1='Work Orders'
                buttons={
                    [
                        { url: 'work-order/add', label: 'Add Work Order' },
                        { url: 'work-orders/all', label: 'All Work Orders' },
                        { url: 'work-orders', label: 'My Work Orders', onClick: (e) => setHiddenColumns([...hiddenColumns, 'workorderAssignee']) },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'work-order', label: 'Work Orders', active: true },
                    ]
                }
            />
            <div className="row">
                <div className="col-12">
                    <CustomTable
                        columns={columns}
                        data={data}
                        height='auto'
                        toggleColumnVisiblity={true}
                        setRefetchColumns={setRefetchColumns}
                        hiddenColumns={hiddenColumns}
                    />
                </div>
            </div>
        </>
    );
};
export default WorkOrders
