import React, { useState } from 'react'
import { ComponentInput, ComponentSelect, ContentHeader, handleSubmitForStore, onChange } from '../../Components/Components'

const AddCategory = () => {

    const [error, setError] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [values, setValues] = useState({
        name: "",
        type: "",
    });

    return (
        <>
            <ContentHeader
                h1='Add categories'
                buttons={
                    [
                        { url: 'categories', label: 'All Categories' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'categories', label: 'Categories', active: false },
                        { url: 'category/add', label: 'Add', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm'>
                <div className="row p-3">
                    <form>
                        <div className="col-12">
                            <div className="mb-3">
                                <ComponentInput
                                    name='name'
                                    type='text'
                                    displayName='Category Name'
                                    className='form-control'
                                    value={values['name']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentSelect
                                    name='type'
                                    displayName='Category Type'
                                    className='form-select'
                                    options={[
                                        { value: 1, label: 'Work Order' },
                                        { value: 2, label: 'Asset' },
                                        { value: 3, label: 'Parts' },
                                        { value: 4, label: 'Contact' },
                                        { value: 5, label: 'Contact Detail' },
                                    ]}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    selected={values['type']}
                                    label='label'
                                    value='value'
                                    error={error}
                                />
                            </div>
                        </div>
                        <div className='col-12 pt-3'>
                            <button onClick={
                                (e) => {
                                    handleSubmitForStore(
                                        e,
                                        'category/add',
                                        {
                                            name: values.name,
                                            type: values.type,
                                        },
                                        setError,
                                        setNotificationMessage
                                    )
                                }
                            } className="btn btn-primary">Submit</button>
                            {notificationMessage}

                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddCategory