import React from 'react'

const NotificationMessage = (props) => {
    return (
        <>
            <p className={`text-${props.type} pt-2`}>
                {props.message}
            </p>
        </>
    )
}

export default NotificationMessage