import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { AxiosGet, ComponentInput, ComponentLoading, ComponentSelect, ContentHeader, handleSubmitForUpdate, onChange } from '../../Components/Components'

const EditVendor = () => {
    const params = useParams()
    const [error, setError] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [dropdown, setDropdown] = useState('');
    const [loading, setLoading] = useState(true);
    const [dataFound, setDataFound] = useState(false);

    const [values, setValues] = useState({
        company_name: "",
        primary_contact_name: "",
        address: "",
        phone: "",
        category_id: "",
        website: "",
        type: 1,
    });

    useEffect(() => {
        (async () => {
            const categories = await AxiosGet('dropdown/categories/4');
            setDropdown({ categories });
        })();
    }, [params.id]);
    useEffect(() => {
        AxiosGet('contact/' + params.id).then(result => {
            setValues({
                // name: result.data.data.name,
                company_name: result.data.data.company_name,
                primary_contact_name: result.data.data.primary_contact_name,
                address: result.data.data.address,
                phone: result.data.data.phone,
                category_id: result.data.data.category_id && result.data.data.category_id.id,
                website: result.data.data.website,
            });
            setLoading(false);
            setDataFound(true);
        }).catch(err => {
            setDataFound(false);
            console.log(err.response, '----')
        })
    }, [params.id]);

    return (
        <>

            <ContentHeader
                h1='Edit Vendor'
                buttons={
                    [
                        { url: 'vendors', label: 'All Vendors' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'vendors', label: 'Vendors', active: false },
                        { url: 'vendors/edit', label: 'Edit', active: true },
                    ]
                }
            />

            <div className='border rounded-2 shadow-sm'>
                <div className="row p-3">
                    {
                        !loading ?
                            <>
                                <form>
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <ComponentInput
                                                name='company_name'
                                                type='text'
                                                displayName='Vendor Name'
                                                className='form-control'
                                                value={values['company_name']}
                                                onChange={(e) => onChange(e, values, setValues)}
                                                error={error}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <ComponentInput
                                                name='primary_contact_name'
                                                type='text'
                                                displayName='Contact Name'
                                                className='form-control'
                                                value={values['primary_contact_name']}
                                                onChange={(e) => onChange(e, values, setValues)}
                                                error={error}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <ComponentInput
                                                name='address'
                                                type='text'
                                                displayName='Address'
                                                className='form-control'
                                                value={values['address']}
                                                onChange={(e) => onChange(e, values, setValues)}
                                                error={error}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <ComponentInput
                                                name='phone'
                                                type='text'
                                                displayName='Contact Number'
                                                className='form-control'
                                                value={values['phone']}
                                                onChange={(e) => onChange(e, values, setValues)}
                                                error={error}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            {dropdown &&
                                                dropdown.categories != '' &&
                                                dropdown.categories != null && (
                                                    <ComponentSelect
                                                        name='category_id'
                                                        displayName='Category'
                                                        className='form-control'
                                                        options={dropdown.categories.data}
                                                        onChange={(e) => onChange(e, values, setValues)}
                                                        selected={values['category_id']}
                                                        label='label'
                                                        value='value'
                                                        error={error}
                                                    />
                                                )}
                                        </div>
                                        <div className="mb-3">
                                            <ComponentInput
                                                name='website'
                                                type='text'
                                                displayName='Website'
                                                className='form-control'
                                                value={values['website']}
                                                onChange={(e) => onChange(e, values, setValues)}
                                                error={error}
                                            />
                                        </div>

                                    </div>
                                    <div className="col-12">
                                        <button onClick={
                                            (e) => {
                                                handleSubmitForUpdate(
                                                    e,
                                                    'contact/edit',
                                                    {
                                                        id: params.id,
                                                        company_name: values.company_name,
                                                        primary_contact_name: values.primary_contact_name,
                                                        address: values.address,
                                                        phone: values.phone,
                                                        category_id: values.category_id,
                                                        website: values.website,
                                                        type: 1,
                                                    },
                                                    setError,
                                                    setNotificationMessage
                                                )
                                            }
                                        } className="btn btn-primary">Submit</button>
                                        {notificationMessage}
                                    </div>
                                </form>
                            </>
                            : <ComponentLoading dataFound={dataFound} />
                    }
                </div>
            </div>
        </>
    )
}

export default EditVendor