import React, { useState, useEffect } from 'react';
import AuthService from '../../services/AuthService';
import CookieService from '../../services/CookieService';
import { AxiosGet } from '../../Components/Components';


const Login = () => {

    const [username, setUsername,] = useState('');
    const [password, setPassword,] = useState('');
    const [isChecked, setIsChecked,] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [passwordShow, setPasswordShow] = useState(false);

    const handleCheck = () => {
        setIsChecked(!isChecked);
    }
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const postData = {
            email: username,
            password: password,
        };
        const response = await AuthService.doUserLogin(postData);
        if (response) {
            AuthService.handleLoginSuccess(response, isChecked)
            // console.log(response)
            if (CookieService.get('access_token') && CookieService.get('access_token') !== 'undefined') {
                // alert('success')
                setError('')
                setSuccess(true);
            } else {
                // console.log(response.data)
                setError(response.data)
            }
        } else {
            alert("Please check your credentials and try agian");
        }
    }
    useEffect(() => {
        if (CookieService.get('access_token') && CookieService.get('access_token') !== 'undefined') {
            AxiosGet('auth-user').then(result => {
                console.log(result.data.status)
                if (result.data && result.data.status === true) {
                    setSuccess(true);
                    window.location.replace("/");
                }
            }).catch(err => {
                // console.log(err.response, '----');
                CookieService.remove('access_token');
                // window.location.replace("/logout");
            })
        }
    }, []);

    return (
        <>
            {success ? (
                window.location.replace("/")
            ) : (
                <>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="card shadow-lg border-0 rounded-lg mt-5">
                                    <div className="card-header">
                                        <h3 className="text-center font-weight-light mt-4">
                                            Tech Track
                                            <small className="x-small d-block text-center font-weight-light mb-4">Assets & Service Management</small>
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handleFormSubmit}>
                                            <div className="form-floating mb-3">
                                                <input
                                                    className="form-control"
                                                    id="inputEmail"
                                                    type="email"
                                                    name="username"
                                                    placeholder='Email address'
                                                    value={username}
                                                    autoComplete="false"
                                                    onChange={(e) => setUsername(e.target.value)} />
                                                <label htmlFor="inputEmail">Email address</label>
                                                <div
                                                    id='email-error'
                                                    className='error text-danger'>
                                                    {error['errors'] && error['errors']['email']}
                                                </div>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input
                                                    className="form-control"
                                                    id="inputPassword"
                                                    type={!passwordShow ? 'password' : 'text'}
                                                    placeholder="Password"
                                                    name="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)} />
                                                <label htmlFor="inputPassword">Password</label>
                                                <div
                                                    id='password-error'
                                                    className='error text-danger'>
                                                    {error['errors'] && error['errors']['password']}
                                                </div>
                                                <div className='form-check pt-2'>
                                                    <input className="form-check-input"
                                                        type="checkbox"
                                                        defaultValue
                                                        id="showPassword"
                                                        onClick={() => setPasswordShow(!passwordShow)}
                                                    />
                                                    <label className="form-check-label" htmlFor="showPassword">
                                                        Show Password
                                                    </label>
                                                </div>
                                            </div>
                                            {/* <div className="form-check mb-3">
                                                    <input
                                                        className="form-check-input"
                                                        id="remember"
                                                        type="checkbox"
                                                        checked={isChecked}
                                                        onChange={handleCheck} />
                                                    <label className="form-check-label" onClick={handleCheck}>Remember Password</label>
                                                </div> */}
                                            <div className="d-flex align-items-center justify-content-between mt-3 mb-0">
                                                {/* <a className="small" href="password.html">Forgot Password?</a> */}
                                                {/* <a className="btn btn-primary" href="index.html">Sign In</a> */}
                                                <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                            </div>
                                            <div
                                                id='team_id-error'
                                                className='error text-danger mt-2'>
                                                {error && !error['errors'] && error['message']}
                                            </div>
                                        </form>
                                    </div>
                                    <div className="card-footer text-center py-3">
                                        <div className="small"><a href="#">Powered by Netmatic Inc.</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default Login
