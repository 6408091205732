import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { ContentHeader, ComponentInput, onChange, ComponentSelect, AxiosGet, handleSubmitForUpdate } from '../../Components/Components'


const EditTeam = () => {

    const params = useParams()
    const [error, setError] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState("");

    const [values, setValues] = useState({
        name: "",
        status: '',
    });

    useEffect(() => {
        (async () => {
            const fetchedData = await AxiosGet('team/' + params.id);
            setValues({
                name: fetchedData.data.data.name,
                status: fetchedData.data.data.status,
            });
        })();
    }, []);

    return (
        <>
            <ContentHeader
                h1='Edit Team'
                buttons={
                    [
                        { url: 'teams', label: 'All Teams' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'teams', label: 'Teams', active: false },
                        { url: 'team/edit', label: 'Edit', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm'>
                <div className="row p-3">
                    <form>
                        <div className="col-12">
                            <div className="mb-3">
                                <ComponentInput
                                    name='name'
                                    type='text'
                                    displayName='Team Name'
                                    className='form-control'
                                    value={values['name']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className='mb-3'>
                                <ComponentSelect
                                    name='status'
                                    displayName='Status'
                                    className='form-select'
                                    options={[
                                        { value: 1, label: 'Active' },
                                        { value: 0, label: 'Inactive' },
                                    ]}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    selected={values['status']}
                                    label='label'
                                    value='value'
                                    error={error}
                                />
                            </div>
                        </div>
                        <div className='col-12 pt-3'>
                            <button onClick={
                                (e) => {
                                    handleSubmitForUpdate(
                                        e,
                                        'team/edit',
                                        {
                                            id: params.id,
                                            name: values.name,
                                            status: values.status,
                                        },
                                        setError,
                                        setNotificationMessage
                                    )
                                }
                            } className="btn btn-primary">Submit</button>
                            {notificationMessage}
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default EditTeam