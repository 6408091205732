import React, { useEffect, useState } from 'react'
import { AxiosGet, ComponentInput, ComponentSelect, ContentHeader, handleSubmitForStore, onChange } from '../../Components/Components'

const AddPart = () => {

    const [error, setError] = useState([]);
    const [dropdown, setDropdown] = useState('');
    const [notificationMessage, setNotificationMessage] = useState("");
    // const [loading, setLoading] = useState(true);

    const [values, setValues] = useState({
        name: "",
        description: "",
        barcode: "",
        category_id: "",
    });

    useEffect(() => {
        (async () => {
            const categories = await AxiosGet('dropdown/categories/3');

            setDropdown({ categories });
        })();
    }, []);


    return (
        <>
            <ContentHeader
                h1='Add Part'
                buttons={
                    [
                        { url: 'Parts', label: 'All Parts' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'parts', label: 'Parts', active: false },
                        { url: 'part/add', label: 'Add', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm'>
                <form>
                    <div className="row p-3">
                        <div className="col-12">
                            <div className="mb-3">
                                <ComponentInput
                                    name='name'
                                    type='text'
                                    displayName='Part Name'
                                    className='form-control'
                                    value={values['name']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentInput
                                    name='description'
                                    type='text'
                                    displayName='Description'
                                    className='form-control'
                                    value={values['description']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentInput
                                    name='barcode'
                                    type='text'
                                    displayName='Barcode'
                                    className='form-control'
                                    value={values['barcode']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>

                            <div className="mb-3">
                                {dropdown.categories &&
                                    <ComponentSelect
                                        name='category_id'
                                        displayName='Category'
                                        className='form-select'
                                        options={dropdown.categories.data}
                                    onChange={(e) => onChange(e, values, setValues)}
                                        selected={values['category_id']}
                                        label='label'
                                        value='value'
                                        error={error}
                                    />
                                }
                            </div>

                        </div>
                        <div className="col-12">
                            <button onClick={
                                (e) => {
                                    handleSubmitForStore(
                                        e,
                                        'part/add',
                                        {
                                            name: values.name,
                                            description: values.description,
                                            barcode: values.barcode,
                                            category_id: values.category_id,
                                        },
                                        setError,
                                        setNotificationMessage
                                    )
                                }
                            } className="btn btn-primary">Submit</button>
                            {notificationMessage}
                        </div>
                    </div>
                </form>
                {/* /.card */}
            </div>
        </>
    )
}

export default AddPart