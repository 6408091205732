import React, { useEffect, useState } from 'react';
import { AxiosGet, ComponentInput, ComponentSelect, ComponentTextarea, ContentHeader, handleSubmitForStore, onChange } from '../../Components/Components';
import Select from 'react-select';

const AddAsset = () => {
    const [error, setError] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [dropdown, setDropdown] = useState('');
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [selectedPrimaryWorker, setSelectedPrimaryWorker] = useState('');
    const [selectedAdditionalWorker, setSelectedAdditionalWorker] = useState('');

    const [values, setValues] = useState({
        name: '',
        description: '',
        // image_path: '',
        location_id: '',
        category_id: '',
        model: '',
        barcode: '',
        purchase_price: '',
        purchase_date: '',
        residual_value: '',
        useful_life: '',
        // purchase_receipt_path: '',
        assignee_id: '',
        additional_assignee_id: '',
        team_id: '',
        vendor_id: '',
        customer_id: '',
        placed_in_service_date: '',
        warranty_expiry: '',
        notes: '',
        // warranty_file_path: '',
        parts_id: '',
        has_parent: '',
        parent_id: '',
        status: '',
    });

    const formdata = new FormData();

    function appendData() {
        let teams = [];
        let worker = '';
        let additional_worker = [];
        if (selectedTeam !== null && selectedTeam !== '') {
            selectedTeam.map((item) => {
                return (
                    teams.push(item.value)
                )
            });
        }
        if (selectedAdditionalWorker !== null && selectedAdditionalWorker !== '') {
            selectedAdditionalWorker.map((item) => {
                return (
                    additional_worker.push(item.value)
                )
            });
        }
        if (selectedPrimaryWorker !== null && selectedPrimaryWorker !== '') {
            worker = selectedPrimaryWorker.value;
        }
        formdata.append("name", values.name);
        formdata.append("description", values.description);
        // formdata.append("image_path", values.image_path);
        formdata.append("location_id", values.location_id);
        formdata.append("category_id", values.category_id);
        formdata.append("model", values.model);
        formdata.append("barcode", values.barcode);
        formdata.append("purchase_price", values.purchase_price);
        formdata.append("purchase_date", values.purchase_date);
        formdata.append("residual_value", values.residual_value);
        formdata.append("useful_life", values.useful_life);
        // formdata.append("purchase_receipt_path", values.purchase_receipt_path);
        formdata.append("assignee_id", worker);
        formdata.append("additional_assignee_id", additional_worker.toString());
        formdata.append("team_id", teams.toString());
        formdata.append("vendor_id", values.vendor_id);
        formdata.append("customer_id", values.customer_id);
        formdata.append("placed_in_service_date", values.placed_in_service_date);
        formdata.append("warranty_expiry", values.warranty_expiry);
        formdata.append("notes", values.notes);
        // formdata.append("warranty_file_path", values.warranty_file_path);
        formdata.append("parent_id", values.parent_id);
        formdata.append("has_parent", values.has_parent);
        formdata.append("status", 1);
    }

    useEffect(() => {
        (async () => {
            const assignee = await AxiosGet('dropdown/assignee');
            const teams = await AxiosGet('dropdown/teams');
            const locations = await AxiosGet('dropdown/locations');
            const categories = await AxiosGet('dropdown/categories/2');
            const vendors = await AxiosGet('dropdown/vendors');

            setDropdown({ assignee, teams, locations, categories, vendors });
        })();
    }, []);

    return (
        <>
            <ContentHeader
                h1='Add assets'
                buttons={[{ url: 'assets', label: 'Assets' }]}
                breadcrumbs={[
                    { url: '', label: 'Dashboard', active: false },
                    { url: 'assets', label: 'Assets', active: false },
                    { url: 'asset/add', label: 'Add', active: true },
                ]}
            />
            <div className='border rounded-2 shadow-sm'>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                        <button className="nav-link active" id="asset-details-tab" data-bs-toggle="tab" data-bs-target="#asset-details" type="button" role="tab" aria-controls="asset-details" aria-selected="true">Asset Details</button>
                    </li>
                    {/* <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                        <button className="nav-link" id="asset-images-tab" data-bs-toggle="tab" data-bs-target="#asset-images" type="button" role="tab" aria-controls="asset-images" aria-selected="false">Asset Images</button>
                    </li> */}
                </ul>
                <div className="tab-content py-3 px-3" id="myTabContent">
                    <div className="tab-pane fade show active" id="asset-details" role="tabpanel" aria-labelledby="asset-details-tab">
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='mb-3'>
                                    <ComponentInput
                                        name='name'
                                        type='text'
                                        displayName='Name'
                                        className='form-control'
                                        value={values['name']}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        error={error}
                                    />
                                </div>
                                <div className='mb-3'>
                                    <ComponentInput
                                        name='model'
                                        type='text'
                                        displayName='Model'
                                        className='form-control'
                                        value={values['model']}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        error={error}
                                    />
                                </div>
                                <div className='mb-3'>
                                    <ComponentInput
                                        name='barcode'
                                        type='text'
                                        displayName='Barcode'
                                        className='form-control'
                                        value={values['barcode']}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        error={error}
                                    />
                                </div>
                                <div className='mb-3'>
                                    <ComponentTextarea
                                        name='description'
                                        type='text'
                                        displayName='Description'
                                        className='form-control'
                                        value={values['description']}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        error={error}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='mb-3'>
                                    {
                                        <ComponentSelect
                                            name='status'
                                            displayName='Status'
                                            className='form-select'
                                            options={[
                                                { value: 1, label: 'Active' },
                                                { value: 0, label: 'Inactive' },
                                            ]}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            selected={values['status']}
                                            label='label'
                                            value='value'
                                            error={error}
                                        />
                                    }
                                </div>
                                <div className='mb-3'>
                                    {dropdown &&
                                        dropdown.categories !== '' &&
                                        dropdown.categories !== null && (
                                            <ComponentSelect
                                                name='category_id'
                                                displayName='Category'
                                            className='form-select'
                                            options={dropdown.categories.data}
                                                onChange={(e) => onChange(e, values, setValues)}
                                                selected={values['category_id']}
                                                label='label'
                                                value='value'
                                                error={error}
                                            />
                                        )}
                                </div>
                                <div className='mb-3'>
                                    {dropdown &&
                                        dropdown.vendors !== '' &&
                                        dropdown.vendors !== null && (
                                            <ComponentSelect
                                                name='vendor_id'
                                                displayName='Vendor'
                                            className='form-select'
                                            options={dropdown.vendors.data}
                                                onChange={(e) => onChange(e, values, setValues)}
                                                selected={values['vendor_id']}
                                                label='label'
                                                value='value'
                                                error={error}
                                            />
                                        )}
                                </div>
                                <div className='mb-3'>
                                    {dropdown &&
                                        dropdown.locations !== '' &&
                                        dropdown.locations !== null && (
                                            <ComponentSelect
                                                name='location_id'
                                                displayName='Asset Location'
                                            className='form-select'
                                            options={dropdown.locations.data}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            selected={values['location_id']}
                                            label='label'
                                            value='value'
                                            error={error}
                                        />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='row border-top mt-3 pt-3'>
                            <div className='col-sm-6'>
                                <div className='mb-3'>
                                    {
                                        <ComponentSelect
                                            name='has_parent'
                                            displayName='Belongs to a parent'
                                            className='form-select'
                                            options={[
                                                { value: 1, label: 'Yes' },
                                                { value: 0, label: 'No' },
                                            ]}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            selected={values['has_parent']}
                                            label='label'
                                            value='value'
                                            error={error}
                                        />
                                    }
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='mb-3'>
                                    <label className='form-label'>Select Parent Asset</label>
                                    <select
                                        name='parent_id'
                                        className='form-select'
                                        required
                                        value={
                                            values['parent_id'] !== '' &&
                                                values['parent_id'] !== null
                                                ? values['parent_id']
                                                : ''
                                        }
                                        disabled={
                                            values['has_parent'] !== 1 ? true : false
                                        }
                                        onChange={(e) => onChange(e, values, setValues)}
                                    >
                                        <option>Select Parent</option>
                                        {
                                            // (locations !== '') &&
                                            // locations.map((item, i) =>
                                            //     <option key={i} value={item.id}>{item.name}</option>
                                            // )
                                        }
                                    </select>
                                    <span
                                        id='parent_id-error'
                                        className='error text-danger'
                                    >
                                        {error && error['parent_id']}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='row border-top mt-3 pt-3'>
                            <div className='col-sm-6'>
                                <div className='mb-3'>
                                    <ComponentInput
                                        name='purchase_price'
                                        type='text'
                                        displayName='Purchase Price'
                                        className='form-control'
                                        value={values['purchase_price']}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        error={error}
                                    />
                                </div>
                                <div className='mb-3'>
                                    <ComponentInput
                                        name='residual_value'
                                        type='text'
                                        displayName='Residual Value'
                                        className='form-control'
                                        value={values['residual_value']}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        error={error}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='mb-3'>
                                    <ComponentInput
                                        name='purchase_date'
                                        type='date'
                                        displayName='Purchase Date'
                                        className='form-control'
                                        value={values['purchase_date']}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        error={error}
                                    />
                                </div>
                                <div className='mb-3'>
                                    <ComponentInput
                                        name='useful_life'
                                        type='text'
                                        displayName='Useful Life (in years)'
                                        className='form-control'
                                        value={values['useful_life']}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        error={error}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row border-top mt-3 pt-3'>
                            <div className='col-sm-4'>
                                <div className='mb-3'>
                                    <label htmlFor='team_id' className='form-label'>
                                        Assigned Team
                                    </label>
                                    {dropdown &&
                                        dropdown.teams !== '' &&
                                        dropdown.teams !== null && (
                                            <Select
                                                name='team_id'
                                                defaultValue={selectedTeam}
                                                onChange={setSelectedTeam}
                                                options={dropdown.teams.data}
                                                isClearable={true}
                                                isMulti
                                            />
                                        )}
                                    <span
                                        id='team_id-error'
                                        className='error text-danger'
                                    >
                                        {error && error['team_id']}
                                    </span>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='mb-3'>
                                    <label htmlFor='assignee_id' className='form-label'>
                                        Primary Technician
                                    </label>
                                    {dropdown &&
                                        dropdown.assignee !== '' &&
                                        dropdown.assignee !== null && (
                                            <Select
                                                name='assignee_id'
                                                defaultValue={selectedPrimaryWorker}
                                                onChange={setSelectedPrimaryWorker}
                                                options={dropdown.assignee.data}
                                                isClearable={true}
                                            />
                                        )}
                                    <span
                                        id='assignee_id-error'
                                        className='error text-danger'
                                    >
                                        {error && error['assignee_id']}
                                    </span>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='mb-3'>
                                    <label htmlFor='additional_assignee_id' className='form-label'>
                                        Additional Technicians
                                    </label>
                                    {dropdown &&
                                        dropdown.assignee !== '' &&
                                        dropdown.assignee !== null && (
                                            <Select
                                                name='additional_assignee_id'
                                                defaultValue={
                                                    selectedAdditionalWorker
                                                }
                                                onChange={setSelectedAdditionalWorker}
                                                options={dropdown.assignee.data}
                                                isOptionDisabled={(option) =>
                                                    option.value === selectedPrimaryWorker.value
                                                }
                                                isClearable={true}
                                                isMulti
                                            />
                                        )}
                                    <span
                                        id='additional_assignee_id-error'
                                        className='error text-danger'
                                    >
                                        {error && error['additional_assignee_id']}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='row border-top mt-3 pt-3'>
                            <div className='col-sm-6'>
                                <div className='mb-3'>
                                    <ComponentInput
                                        name='placed_in_service_date'
                                        type='date'
                                        displayName='Warranty Start Date'
                                        className='form-control'
                                        value={values['placed_in_service_date']}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        error={error}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='mb-3'>
                                    <ComponentInput
                                        name='warranty_expiry'
                                        type='date'
                                        displayName='Warranty Expiry Date'
                                        className='form-control'
                                        value={values['warranty_expiry']}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        error={error}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='mb-3'>
                                    <ComponentTextarea
                                        name='notes'
                                        type='text'
                                        displayName='Notes'
                                        className='form-control'
                                        value={values['notes']}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        error={error}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="asset-images" role="tabpanel" aria-labelledby="asset-images-tab">
                        <div className='row'>

                        </div>
                    </div>
                </div>
                <div className='row pb-3 px-3'>
                    <div className='col-12'>
                        <button
                            onClick={
                                (e) => {
                                    appendData()
                                    handleSubmitForStore(
                                        e,
                                        'asset/add',
                                        formdata,
                                        setError,
                                        setNotificationMessage
                                    )
                                }
                            }
                            className='btn btn-primary'
                        >
                            Submit
                        </button>
                        {notificationMessage}
                    </div>
                </div>
            </div>

        </>
    );
};

export default AddAsset;
