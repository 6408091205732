import React from 'react'
import { ContentHeader } from '../Components/Components';

const Dashboard = () => {
    return (
        <>
            <ContentHeader
                h1='Dashboard'
                // buttons={
                //     [
                //         { url: 'customer/add', label: 'Add Customer' },
                //     ]
                // }
                breadcrumbs={
                    [
                        { url: '/', label: 'Dashboard', active: true },
                    ]
                }
            />
            <div>
                <h5>Custom KPI's</h5>
            </div>
        </>
    )
}

export default Dashboard