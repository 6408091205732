import React, { useEffect, useState } from 'react';
import { AxiosGet, ContentHeader } from '../../Components/Components';
import { CustomTable, SelectColumnFilter } from '../../Components/CustomTable/CustomReactTable';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';


const People = () => {

    // React-table start
    const [data, setData] = useState([]);

    function teamDetails(row) {
        if (row.teams && row.teams !== null && !isEmpty(row.teams)) {
            let teamFilter = '';
            row.teams.map((item, i) => {
                return (
                    teamFilter += item.name + ', '
                )
            })
            teamFilter = teamFilter.substring(0, teamFilter.length - 2);
            return teamFilter;
        }
    }

    const columns = React.useMemo(
        () => [
            {
                accessor: 'name',
                Header: 'Name',
                Cell: ({ cell }) => (
                    <>
                        <Link className='mx-2' to={`/people/view/${cell.row.original.id}`}>
                            {cell.value}
                        </Link>
                    </>
                )
            },
            {
                accessor: 'email',
                Header: 'Email',
            },
            {
                accessor: 'phone',
                Header: 'Phone',
            },
            {
                accessor: 'job_title',
                Header: 'Job Title',
            },
            {
                accessor: teamDetails,
                Header: 'Team',
                Filter: SelectColumnFilter,
                Cell: ({ cell }) => (
                    (cell.row.original.teams && cell.row.original.teams !== '' && cell.row.original.teams !== null) &&
                    cell.row.original.teams.map((item, i) => {
                        return <span key={i}>
                            {/* <Link to={`/team/view/${item.id}`}> */}
                            {item.name}
                            {/* </Link> */}
                            {(i < cell.row.original.teams.length - 1) && ', '}
                        </span>
                    })
                )
            },
            {
                accessor: 'id',
                Header: 'Action',
                disableFilters: true,
                Cell: ({ cell }) => (
                    <>
                        {/* {console.log(cell.row)} */}
                        <Link className='mx-2' to={`/people/edit/${cell.row.original.id}`}>
                            <i className="far fa-edit text-primary"></i>
                        </Link>
                        {/* <Link className='' to={`/people/delete/${cell.row.original.id}`}>
                            <i className="fas fa-trash-alt text-danger"></i>
                        </Link> */}
                    </>
                )
            },
        ],
        []
    )

    useEffect(() => {
        AxiosGet('people').then(result => {
            setData(result.data.data);
            // console.log(document.cookie)
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, []);
    // React-table end

    return (
        <>
            <ContentHeader
                h1='People'
                buttons={
                    [
                        { url: 'people/add', label: 'Add People' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'people', label: 'People', active: true },
                    ]
                }
            />
            <div className="row">
                <div className="col-12">
                    <CustomTable
                        columns={columns}
                        data={data}
                        height='auto'
                    />
                </div>
            </div>
        </>
    )
}
export default People;
