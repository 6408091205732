import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiUrl, assetUrl, AxiosPost, AxiosGet, ComponentInput, ComponentSelect, ComponentTextarea, ContentHeader, handleSubmitForUpdate, onChange, ComponentLoading, AxiosDelete, AxiosPut } from '../../Components/Components';
import Select from 'react-select';
import { useParams } from 'react-router-dom';

import * as qs from 'qs';
import toast from 'react-simple-toasts';
import PdfPlaceholder from '../../Assets/Images/pdf_placeholder.png';
import ReadQr from '../../Components/QrCode/ReadQr';
import { CustomTable, SelectColumnFilter } from '../../Components/CustomTable/CustomReactTable';


const EditAsset = () => {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [dropdown, setDropdown] = useState('');
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [selectedPrimaryWorker, setSelectedPrimaryWorker] = useState('');
    const [selectedAdditionalWorker, setSelectedAdditionalWorker] = useState('');

    // QR Scanned Part Value Start
    const [qrScannedPartId, setQrScannedPartId] = useState([]);
    // QR Scanned Part Value End

    const [inventory, setInventory] = useState('');
    const [assetParts, setAssetParts] = useState('');
    const [selectedRows, setSelectedRows] = useState('');

    // Refetch counter start    
    const [refetchDocuments, setRefetchDocuments] = useState(0);
    const [refetchAssetParts, setRefetchAssetParts] = useState(0);
    // Refetch counter end


    const [values, setValues] = useState({
        name: '',
        description: '',
        image_path: '',
        location_id: '',
        category_id: '',
        model: '',
        barcode: '',
        purchase_price: '',
        purchase_date: '',
        residual_value: '',
        useful_life: '',
        purchase_receipt_path: '',
        assignee_id: '',
        additional_assignee_id: '',
        team_id: '',
        vendor_id: '',
        customer_id: '',
        placed_in_service_date: '',
        warranty_expiry: '',
        notes: '',
        warranty_file_path: '',
        parts_id: '',
        has_parent: '',
        parent_id: '',
        status: '',
    });

    const [documentList, setDocumentList] = useState('');
    const [document, setDocument] = useState({
        name: '',
        description: '',
        document_path: '',
    });
    // Values for Axios Get and component data end

    const formdata = new FormData();

    function addDocument() {
        formdata.append("name", document.name);
        formdata.append("description", document.description);
        formdata.append("document_path", document.document_path);
        formdata.append("type", 1);
        formdata.append("relation_key", params.id);
        formdata.append("tag", 1);
        if (true) {
            AxiosPost('document/add',
                formdata
            ).then(result => {
                setDocument({
                    name: '',
                    document_path: ''
                })
                setError('')
                setRefetchDocuments(new Date().getTime())
            }).catch(err => {
                setError(err.response.data.errors)
                console.log(err.response, '----')
            })
        }
    }

    function removeDocument(id) {
        if (true) {
            var data = qs.stringify({
                'id': id
            });
            var config = {
                method: 'delete',
                url: apiUrl + 'document/remove',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };
            axios(config).then(result => {
                setRefetchDocuments(new Date().getTime())
            }).catch(err => {
                console.log(err.response, '----')
            })
        }
    }

    useEffect(() => {
        (async () => {
            const assignee = await AxiosGet('dropdown/assignee');
            const teams = await AxiosGet('dropdown/teams');
            const locations = await AxiosGet('dropdown/locations');
            const categories = await AxiosGet('dropdown/categories/2');
            const vendors = await AxiosGet('dropdown/vendors');
            setDropdown({ assignee, teams, locations, categories, vendors });
            const inventory = await AxiosGet('part-consumption/list');
            setInventory(inventory.data.data);
            const result = await AxiosGet('asset/' + params.id);
            setSelectedPrimaryWorker(result.data.worker);
            setSelectedTeam(result.data.team);
            setSelectedAdditionalWorker(result.data.additional_worker);
            setAssetParts(result.data.asset_parts)
            setValues({
                name: result.data.data.name,
                description: result.data.data.description,
                location_id: result.data.data.location_id,
                category_id: result.data.data.category_id,
                model: result.data.data.model,
                barcode: result.data.data.barcode,
                purchase_price: result.data.data.purchase_price,
                purchase_date: result.data.data.purchase_date,
                residual_value: result.data.data.residual_value,
                useful_life: result.data.data.useful_life,
                assignee_id: result.data.data.assignee_id,
                additional_assignee_id: result.data.data.additional_assignee_id,
                team_id: result.data.data.team_id,
                vendor_id: result.data.data.vendor_id,
                customer_id: result.data.data.customer_id,
                placed_in_service_date: result.data.data.placed_in_service_date,
                warranty_expiry: result.data.data.warranty_expiry,
                notes: result.data.data.notes,
                parts_id: result.data.data.parts_id,
                has_parent: result.data.data.has_parent,
                parent_id: result.data.data.parent_id,
                status: result.data.data.status,
            });
            // console.log(inventory.data)
            // console.log(result.data.asset_parts)
            setLoading(false);
        })();
    }, [refetchAssetParts, params.id]);

    useEffect(() => {
        AxiosGet('documents/asset/' + params.id).then(result => {
            // console.log(result);
            setDocumentList(result.data.data)
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, [refetchDocuments]);

    function addPartsInAsset(part_id, inventory_id, cost) {
        // console.log(part_id)
        // return false
        AxiosPost('asset-part/add',
            {
                'asset_id': params.id,
                'part_id': part_id,
                'inventory_id': inventory_id,
                'quantity': 1,
                'unit_cost': cost,
                'status': 1,
            },
        ).then(result => {
            setRefetchAssetParts(new Date().getTime())
        }).catch(err => {
            console.log(err.response)
            toast('Part already added!', {
                position: 'bottom-right',
                clickable: true,
                clickClosable: true,
                time: 3000,
                render: message => <div className="mb-3 alert alert-danger" role="alert">
                    {message}
                </div>
            })
        })
    }

    function removePartsFromAsset(id) {
        if (true) {
            var data = qs.stringify({
                'id': id
            });
            AxiosDelete('asset-part/remove', data).then(result => {
                setRefetchAssetParts(new Date().getTime())
            }).catch(err => {
                console.log(err.response, '----')
            })
        }
    }

    function updatePartQuantityInAsset(id, quantity) {
        if (true) {
            AxiosPut('asset-part/edit',
                {
                    id: id,
                    quantity: quantity,
                },
            ).then(result => {
                setRefetchAssetParts(new Date().getTime())
                toast('Quantity Updated!', {
                    position: 'bottom-right',
                    clickable: true,
                    clickClosable: true,
                    time: 3000,
                    render: message => <div style={{ zIndex: 99999999 }} className="py-1 mb-3 alert alert-success" role="alert">
                        {message}
                    </div>
                })
            }).catch(err => {
                console.log(err.response, '----')
            })
        }

    }
    // React-table start
    function quantityInStock(row) {
        return Number(
            row.quantity_total - row.quantity_consumed
        )
    }

    const columns = React.useMemo(
        () => [
            {
                id: 'part_name',
                accessor: 'part_name',
                Header: 'Part',
            },
            {
                accessor: 'description',
                Header: 'Description',
            },
            {
                // accessor: 'in_stock',
                Header: 'Quantity',
                accessor: quantityInStock,
            },
            {
                accessor: 'location_name',
                Header: 'Location',
                Filter: SelectColumnFilter,
                // filter: 'includes',
            },
            {
                accessor: 'inventory_id',
                Header: 'Action',
                disableFilters: true,
                Cell: ({ cell }) => (
                    <button
                        className='btn btn-sm btn-primary'
                        onClick={() => addPartsInAsset(cell.row.original.part_id, cell.row.original.inventory_id, cell.row.original.min_cost)}>
                        {/* {console.log(cell.row.original)} */}
                        Add
                    </button>
                )
            },
        ],
        []
    )
    // React-table end

    return (
        <>
            <ContentHeader
                h1='Edit Asset'
                buttons={[{ url: 'assets', label: 'Assets' }]}
                breadcrumbs={[
                    { url: '', label: 'Dashboard', active: false },
                    { url: 'assets', label: 'Assets', active: false },
                    { url: 'asset/edit', label: 'Edit', active: true },
                ]}
            />
            {
                !loading ?
                    <>
                        <div className='border rounded-2 shadow-sm'>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                                    <button className="nav-link active" id="asset-details-tab" data-bs-toggle="tab" data-bs-target="#asset-details" type="button" role="tab" aria-controls="asset-details" aria-selected="true">Asset Details</button>
                                </li>
                                <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                                    <button className="nav-link" id="asset-images-tab" data-bs-toggle="tab" data-bs-target="#asset-images" type="button" role="tab" aria-controls="asset-images" aria-selected="false">Asset Images</button>
                                </li>
                                <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                                    <button className="nav-link" id="asset-parts-tab" data-bs-toggle="tab" data-bs-target="#asset-parts" type="button" role="tab" aria-controls="asset-images" aria-selected="false">Asset Parts</button>
                                </li>
                            </ul>
                            <div className="tab-content py-3 px-3" id="myTabContent">
                                <div className="tab-pane fade show active" id="asset-details" role="tabpanel" aria-labelledby="asset-details-tab">
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className='mb-3'>
                                                <ComponentInput
                                                    name='name'
                                                    type='text'
                                                    displayName='Name'
                                                    className='form-control'
                                                    value={values['name']}
                                                    onChange={(e) => onChange(e, values, setValues)}
                                                    error={error}
                                                />
                                            </div>
                                            <div className='mb-3'>
                                                <ComponentInput
                                                    name='model'
                                                    type='text'
                                                    displayName='Model'
                                                    className='form-control'
                                                    value={values['model']}
                                                    onChange={(e) => onChange(e, values, setValues)}
                                                    error={error}
                                                />
                                            </div>
                                            <div className='mb-3'>
                                                <ComponentInput
                                                    name='barcode'
                                                    type='text'
                                                    displayName='Barcode'
                                                    className='form-control'
                                                    value={values['barcode']}
                                                    onChange={(e) => onChange(e, values, setValues)}
                                                    error={error}
                                                />
                                            </div>
                                            <div className='mb-3'>
                                                <ComponentTextarea
                                                    name='description'
                                                    type='text'
                                                    displayName='Description'
                                                    className='form-control'
                                                    value={values['description']}
                                                    onChange={(e) => onChange(e, values, setValues)}
                                                    error={error}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='mb-3'>
                                                {
                                                    <ComponentSelect
                                                        name='status'
                                                        displayName='Status'
                                                        className='form-select'
                                                        options={[
                                                            { value: 1, label: 'Active' },
                                                            { value: 0, label: 'Inactive' },
                                                        ]}
                                                        onChange={(e) => onChange(e, values, setValues)}
                                                        selected={values['status']}
                                                        label='label'
                                                        value='value'
                                                        error={error}
                                                    />
                                                }
                                            </div>
                                            <div className='mb-3'>
                                                {dropdown &&
                                                    dropdown.categories != '' &&
                                                    dropdown.categories != null && (
                                                        <ComponentSelect
                                                            name='category_id'
                                                            displayName='Category'
                                                            className='form-select'
                                                            options={dropdown.categories.data}
                                                            onChange={(e) => onChange(e, values, setValues)}
                                                            selected={values['category_id']}
                                                            label='label'
                                                            value='value'
                                                            error={error}
                                                        />
                                                    )}
                                            </div>
                                            <div className='mb-3'>
                                                {dropdown &&
                                                    dropdown.vendors != '' &&
                                                    dropdown.vendors != null && (
                                                        <ComponentSelect
                                                            name='vendor_id'
                                                            displayName='Vendor'
                                                            className='form-select'
                                                            options={dropdown.vendors.data}
                                                            onChange={(e) => onChange(e, values, setValues)}
                                                            selected={values['vendor_id']}
                                                            label='label'
                                                            value='value'
                                                            error={error}
                                                        />
                                                    )}
                                            </div>
                                            <div className='mb-3'>
                                                {dropdown &&
                                                    dropdown.locations != '' &&
                                                    dropdown.locations != null && (
                                                        <ComponentSelect
                                                            name='location_id'
                                                            displayName='Asset Location'
                                                            className='form-select'
                                                            options={dropdown.locations.data}
                                                            onChange={(e) => onChange(e, values, setValues)}
                                                            selected={values['location_id']}
                                                            label='label'
                                                            value='value'
                                                            error={error}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row border-top mt-3 pt-3'>
                                        <div className='col-sm-6'>
                                            <div className='mb-3'>
                                                {
                                                    <ComponentSelect
                                                        name='has_parent'
                                                        displayName='Belongs to a parent'
                                                        className='form-select'
                                                        options={[
                                                            { value: 1, label: 'Yes' },
                                                            { value: 0, label: 'No' },
                                                        ]}
                                                        onChange={(e) => onChange(e, values, setValues)}
                                                        selected={values['has_parent']}
                                                        label='label'
                                                        value='value'
                                                        error={error}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='mb-3'>
                                                <label className='form-label'>Select Parent Asset</label>
                                                <select
                                                    name='parent_id'
                                                    className='form-select'
                                                    required
                                                    value={
                                                        values['parent_id'] != '' &&
                                                            values['parent_id'] != null
                                                            ? values['parent_id']
                                                            : ''
                                                    }
                                                    disabled={
                                                        values['has_parent'] != 1 ? true : false
                                                    }
                                                    onChange={(e) => onChange(e, values, setValues)}
                                                >
                                                    <option>Select Parent</option>
                                                    {
                                                        // (locations != '') &&
                                                        // locations.map((item, i) =>
                                                        //     <option key={i} value={item.id}>{item.name}</option>
                                                        // )
                                                    }
                                                </select>
                                                <span
                                                    id='parent_id-error'
                                                    className='error text-danger'
                                                >
                                                    {error && error['parent_id']}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row border-top mt-3 pt-3'>
                                        <div className='col-sm-6'>
                                            <div className='mb-3'>
                                                <ComponentInput
                                                    name='purchase_price'
                                                    type='text'
                                                    displayName='Purchase Price'
                                                    className='form-control'
                                                    value={values['purchase_price']}
                                                    onChange={(e) => onChange(e, values, setValues)}
                                                    error={error}
                                                />
                                            </div>
                                            <div className='mb-3'>
                                                <ComponentInput
                                                    name='residual_value'
                                                    type='text'
                                                    displayName='Residual Value'
                                                    className='form-control'
                                                    value={values['residual_value']}
                                                    onChange={(e) => onChange(e, values, setValues)}
                                                    error={error}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='mb-3'>
                                                <ComponentInput
                                                    name='purchase_date'
                                                    type='date'
                                                    displayName='Purchase Date'
                                                    className='form-control'
                                                    value={values['purchase_date']}
                                                    onChange={(e) => onChange(e, values, setValues)}
                                                    error={error}
                                                />
                                            </div>
                                            <div className='mb-3'>
                                                <ComponentInput
                                                    name='useful_life'
                                                    type='text'
                                                    displayName='Useful Life (in years)'
                                                    className='form-control'
                                                    value={values['useful_life']}
                                                    onChange={(e) => onChange(e, values, setValues)}
                                                    error={error}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row border-top mt-3 pt-3'>
                                        <div className='col-sm-4'>
                                            <div className='mb-3'>
                                                <label className='form-label'>
                                                    Assigned Team
                                                </label>
                                                {dropdown &&
                                                    dropdown.teams != '' &&
                                                    dropdown.teams != null && values.team_id && (
                                                        <Select
                                                            name='team_id'
                                                            defaultValue={selectedTeam}
                                                            onChange={setSelectedTeam}
                                                            options={dropdown.teams.data}
                                                            isClearable={true}
                                                            isMulti
                                                        />
                                                    )}
                                                <span
                                                    id='team_id-error'
                                                    className='error text-danger'
                                                >
                                                    {error && error['team_id']}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className='mb-3'>
                                                <label className='form-label'>
                                                    Primary Technician
                                                </label>
                                                {dropdown &&
                                                    dropdown.assignee != '' &&
                                                    dropdown.assignee != null && values.assignee_id && (
                                                        <Select
                                                            name='assignee_id'
                                                            defaultValue={selectedPrimaryWorker}
                                                            onChange={setSelectedPrimaryWorker}
                                                            options={dropdown.assignee.data}
                                                            isClearable={true}
                                                        />
                                                    )}

                                                <span
                                                    id='assignee_id-error'
                                                    className='error text-danger'
                                                >
                                                    {error && error['assignee_id']}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className='mb-3'>
                                                <label className='form-label'>
                                                    Additional Technicians
                                                </label>
                                                {dropdown &&
                                                    dropdown.assignee != '' &&
                                                    dropdown.assignee != null && values.additional_assignee_id && (
                                                        <Select
                                                            name='additional_assignee_id'
                                                            defaultValue={
                                                                selectedAdditionalWorker
                                                            }
                                                            onChange={setSelectedAdditionalWorker}
                                                            options={dropdown.assignee.data}
                                                            isOptionDisabled={(option) =>
                                                                option.value === selectedPrimaryWorker.value
                                                            }
                                                            isClearable={true}
                                                            isMulti
                                                        />
                                                    )}
                                                <span
                                                    id='additional_assignee_id-error'
                                                    className='error text-danger'
                                                >
                                                    {error && error['additional_assignee_id']}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row border-top mt-3 pt-3'>
                                        <div className='col-sm-6'>
                                            <div className='mb-3'>
                                                <ComponentInput
                                                    name='placed_in_service_date'
                                                    type='date'
                                                    displayName='Warranty Start Date'
                                                    className='form-control'
                                                    value={values['placed_in_service_date']}
                                                    onChange={(e) => onChange(e, values, setValues)}
                                                    error={error}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='mb-3'>
                                                <ComponentInput
                                                    name='warranty_expiry'
                                                    type='date'
                                                    displayName='Warranty Expiry Date'
                                                    className='form-control'
                                                    value={values['warranty_expiry']}
                                                    onChange={(e) => onChange(e, values, setValues)}
                                                    error={error}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-sm-12'>
                                            <div className='mb-3'>
                                                <ComponentTextarea
                                                    name='notes'
                                                    type='text'
                                                    displayName='Notes'
                                                    className='form-control'
                                                    value={values['notes']}
                                                    onChange={(e) => onChange(e, values, setValues)}
                                                    error={error}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="asset-images" role="tabpanel" aria-labelledby="asset-images-tab">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h5>Document / Images</h5>
                                            <div className="row gx-3 gy-2 align-items-end  pb-4 mt-2">
                                                <div className="col-auto">
                                                    <ComponentInput
                                                        name='name'
                                                        type='text'
                                                        displayName='Title'
                                                        className='form-control'
                                                        value={document['name']}
                                                        onChange={(e) => onChange(e, document, setDocument)}
                                                        error={error}
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <ComponentInput
                                                        name='description'
                                                        type='text'
                                                        displayName='Description'
                                                        className='form-control'
                                                        value={document['description']}
                                                        onChange={(e) => onChange(e, document, setDocument)}
                                                        error={error}
                                                    />
                                                </div>
                                                <div className="col-auto">
                                                    <label className="form-label fw-semibold" htmlFor="document_path">Document</label>
                                                    <input
                                                        type="file"
                                                        onChange={
                                                            (e) => setDocument({ ...document, [e.target.name]: e.target.files[0] })
                                                        }
                                                        name="document_path"
                                                        className="form-control"
                                                        id="document_path" />
                                                    <span id='document_path-error' className='error text-danger'>
                                                        {error && error['document_path']}
                                                    </span>
                                                </div>
                                                <div className="col-auto">
                                                    <button
                                                        className='btn btn-primary'
                                                        onClick={
                                                            () => addDocument()
                                                        }>
                                                        Add Image
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='row border-top mt-3 py-4 d-flex justify-content-md-start justify-content-center'>
                                                {
                                                    (!loading && documentList && documentList != '') ?
                                                        documentList.map((item, i) => (
                                                            <div key={i} className='col-auto d-flex flex-column my-2'>
                                                                <div className="card shadow-sm" style={{ width: '14rem' }}>
                                                                    <img
                                                                        src={item.document_path.split('.').reverse()[0] == 'pdf' ? PdfPlaceholder : assetUrl + item.document_path}
                                                                        style={{ height: '150px', objectFit: 'cover' }}
                                                                        className="card-img-top"
                                                                        alt={item.name} />
                                                                    <div className="card-body py-2">
                                                                        <h6 className="card-text">{item.name}</h6>
                                                                        <p className="card-text" style={{ fontSize: '0.85rem' }}>
                                                                            {
                                                                                item.description != '' && item.description != null ?
                                                                                    item.description : 'No description available!'
                                                                            }
                                                                        </p>
                                                                        <div className='d-flex justify-content-between'>
                                                                            <span
                                                                                onClick={() => { removeDocument(item.id) }}
                                                                                style={{ cursor: 'pointer' }}
                                                                                className="card-link">
                                                                                <i className='fas fa-trash-alt text-danger'></i>
                                                                            </span>
                                                                            <a href={assetUrl + item.document_path}
                                                                                target='_blank'
                                                                                className="card-link text-decoration-none">
                                                                                View &nbsp;
                                                                                <i className="fas fa-external-link"></i>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                        :
                                                        <span>
                                                            No documents added yet...
                                                        </span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="asset-parts" role="tabpanel" aria-labelledby="asset-parts-tab">
                                    <div className='row'>
                                        <div className='col-md-7'>
                                            <div className='row border mx-2 my-2 py-3 shadow-sm'>
                                                <div className="d-flex flex-row justify-content-between pb-2">
                                                    <div className="">
                                                        <h5>Search Parts</h5>
                                                    </div>
                                                    <div className="">
                                                        <ReadQr setQrScannedPartId={setQrScannedPartId} id='part_name' />
                                                    </div>
                                                </div>
                                                {inventory &&
                                                    <CustomTable
                                                        columns={columns}
                                                        data={inventory}
                                                        height='auto'
                                                        selectedRows={selectedRows}
                                                        onSelectedRowsChange={setSelectedRows}
                                                        filterData={qrScannedPartId}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className='col-md-5'>
                                            <div className='row border mx-2 my-2 py-3 shadow'>
                                                <h5>Asset Parts</h5>
                                                <div className='table-responsive'>
                                                    <table className='table table-stripped table-hover'>
                                                        <thead>
                                                            <tr>
                                                                <th>Part</th>
                                                                <th>Qty</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                !loading && assetParts && assetParts !== '' ?
                                                                    assetParts.map((item, i) => {
                                                                        if (item.part) {
                                                                            return <tr key={i}>
                                                                                <td>
                                                                                    {item.part.name} &nbsp;
                                                                                    <small className='x-small'>
                                                                                        ({item.inventory.location.name})
                                                                                    </small>
                                                                                </td>
                                                                                <td width={100}>
                                                                                    <input
                                                                                        value={item.quantity}
                                                                                        className='form-control py-0'
                                                                                        type='number'
                                                                                        min={1}
                                                                                        onChange={(e) => updatePartQuantityInAsset(item.id, e.target.value)} />
                                                                                </td>
                                                                                <td width={30}>
                                                                                    <span onClick={() => { removePartsFromAsset(item.id) }} style={{ cursor: 'pointer' }}>
                                                                                        <i className='fas fa-trash-alt text-danger'></i>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        } else {
                                                                            return null
                                                                        }
                                                                    }) :
                                                                    <tr>
                                                                        <td colSpan={3}>No parts added yet...</td>
                                                                    </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row pb-3 px-3'>
                                <div className='col-12'>
                                    <button
                                        onClick={
                                            (e) => {
                                                let teams = [];
                                                let worker = '';
                                                let additional_worker = [];
                                                if (selectedTeam != null && selectedTeam != '') {
                                                    selectedTeam.map((item) => {
                                                        teams.push(item.value);
                                                    });
                                                }
                                                if (selectedAdditionalWorker != null && selectedAdditionalWorker != '') {
                                                    selectedAdditionalWorker.map((item) => {
                                                        additional_worker.push(item.value);
                                                    });
                                                }
                                                if (selectedPrimaryWorker != null && selectedPrimaryWorker != '') {
                                                    worker = selectedPrimaryWorker.value;
                                                }
                                                handleSubmitForUpdate(
                                                    e,
                                                    'asset/edit',
                                                    {
                                                        id: params.id,
                                                        name: values.name,
                                                        description: values.description,
                                                        location_id: values.location_id,
                                                        category_id: values.category_id,
                                                        model: values.model,
                                                        barcode: values.barcode,
                                                        purchase_price: values.purchase_price,
                                                        purchase_date: values.purchase_date,
                                                        residual_value: values.residual_value,
                                                        useful_life: values.useful_life,
                                                        assignee_id: parseInt(worker),
                                                        additional_assignee_id: additional_worker.toString(),
                                                        team_id: teams.toString(),
                                                        vendor_id: values.vendor_id,
                                                        customer_id: values.customer_id,
                                                        placed_in_service_date: values.placed_in_service_date,
                                                        warranty_expiry: values.warranty_expiry,
                                                        notes: values.notes,
                                                        parts_id: values.parts_id,
                                                        has_parent: values.has_parent,
                                                        status: values.status,
                                                    },
                                                    setError,
                                                    setNotificationMessage
                                                )
                                            }
                                        }
                                        className='btn btn-primary'
                                    >
                                        Submit
                                    </button>
                                    {notificationMessage}
                                </div>
                            </div>
                        </div>

                    </>
                    : <ComponentLoading />
            }
        </>
    );
};

export default EditAsset;
